import React, { useEffect, useState } from "react";
import _ from "lodash";
import DateSelector from "../dateselector/dateselector";
import FilterInput from "../filterinputs/filterinput";
import MoreFilters from "../morefilters/morefilters";
import {
  setUpdateLoading,
  saveCriteria,
  saveRowSelected,
  saveHighlightedAirline,
  saveHighlightedDest,
  saveHighlightedOrigin,
  saveHighlightedAirlineUpdate,
  saveHighlightedDestUpdate,
  saveHighlightedOriginUpdate,
} from "../../redux/App/app.actions";
import "./filters.css";
import { connect } from "react-redux";
import Loader from "../loader/spinner";
import bookingCriteriaFactory from "../../utilities/bookingCriteriaFactory";
import CommonService from "../../utilities/commonService";

function Filters(props) {
  const [criteria, setCriteria] = useState({
    selectedOrigin: "",
    selectedDestination: "",
    selectedDate: {
      year: String(new Date().getFullYear()),
      month: String(new Date().getMonth() + 1),
      specificDate: "",
      week: "",
      quarter: "",
    },
    period: "fullPeriod",
    dateType: "booking",
    periodComparisonType: "samePeriodPreviousYear",
    pointOfSale: "",
    market: "",
    marketingCarrier: "",
    highlightedmarketingCarrier: undefined,
    highlightedOnd: undefined,
    cabinClass: "",
    sortBy: "travel_agency",
  });
  const [provisionalCriteria, setprovisionalCriteria] = useState({
    selectedOrigin: "",
    selectedDestination: "",
    selectedDate: {
      year: String(new Date().getFullYear()),
      month: String(new Date().getMonth() + 1),
      specificDate: "",
      week: "",
      quarter: "",
    },
    period: "fullPeriod",
    dateType: "booking",
    periodComparisonType: "samePeriodPreviousYear",
    pointOfSale: "",
    market: "",
    marketingCarrier: "",
    highlightedmarketingCarrier: undefined,
    highlightedOnd: undefined,
    cabinClass: "",
    sortBy: "travel_agency",
  });
  const [disallowPeriodToDateSelection, setDisallowPeriodToDateSelection] =
    useState(false);
  const [disallowPreviousPeriodSelection, setDisallowPreviousPeriodSelection] =
    useState(false);

  const checkToAllowOrDisallowPeriodTodate = () => {
    var limitToDays =
      bookingCriteriaFactory().getPeriodToDate(provisionalCriteria);
    var currentYear = new Date();
    var currentYearNum = currentYear.getFullYear();
    var scopeYear = parseInt(provisionalCriteria.selectedDate.year);

    setDisallowPeriodToDateSelection(
      !(
        limitToDays !== undefined &&
        limitToDays >= 0 &&
        scopeYear === currentYearNum
      )
    );
  };

  const [showUpdate, setShowUpdate] = useState("false");

  useEffect(() => {
    setShowUpdate(!_.isEqual(provisionalCriteria, criteria));
  }, [_.isEqual(provisionalCriteria, criteria)]);

  useEffect(() => {
    if (disallowPeriodToDateSelection) {
      setprovisionalCriteria((prev) => ({
        ...prev,
        period: "fullPeriod",
      }));
    }
  }, [disallowPeriodToDateSelection]);

  useEffect(() => {
    if (disallowPreviousPeriodSelection) {
      setprovisionalCriteria((prev) => ({
        ...prev,
        periodComparisonType: "samePeriodPreviousYear",
      }));
    }
  }, [disallowPreviousPeriodSelection]);

  useEffect(() => {
    checkToAllowOrDisallowPeriodTodate();
    if (
      provisionalCriteria.selectedDate.specificDate !== "" &&
      provisionalCriteria.dateType !== "departure"
    ) {
      setDisallowPreviousPeriodSelection(false);
    } else if (
      provisionalCriteria.selectedDate.specificDate !== "" &&
      provisionalCriteria.dateType === "departure"
    ) {
      setDisallowPreviousPeriodSelection(true);
    } else {
      setDisallowPreviousPeriodSelection(false);
    }
  }, [provisionalCriteria]);

  const retrieveLocations = (input) => {
    if (input.length < 2) {
      return [];
    }
    return CommonService().getAirportsTypeAhead(input);
  };
  const retrieveAirlines = (input) => {
    if (input.length < 2) {
      return [];
    }
    return CommonService().getMarketingCarriersTypeAhead(input);
  };
  const saveSelectedRowForUpdate = () => {
    const row = props.rowSelected;
    if (row !== "") {
      props.saveHighlightedAirlineUpdate("");
      props.saveHighlightedDestUpdate("");
      props.saveHighlightedOriginUpdate("");
      if (row.length <= 3) {
        retrieveAirlines(row).then((matches) => {
          const results = matches.map((item) => [
            (item.iata_code === undefined ? "" : item.iata_code) +
              " (" +
              item.name +
              ")",
            item,
          ]);
          if (results.length === 0) {
            props.saveHighlightedAirlineUpdate(row);
          } else {
            props.saveHighlightedAirlineUpdate(results.slice(0, 1));
          }
        });
      } else {
        const od = row.split("-");
        retrieveLocations(od[0]).then((matches) => {
          const results = matches.map((item) => [
            item.id + " (" + item.name + ")",
            item,
          ]);
          if (results.length === 0) {
            props.saveHighlightedOriginUpdate(od[0]);
          } else {
            props.saveHighlightedOriginUpdate(results.slice(0, 1));
          }
        });
        retrieveLocations(od[1]).then((matches) => {
          const results = matches.map((item) => [
            item.id + " (" + item.name + ")",
            item,
          ]);
          if (results.length === 0) {
            props.saveHighlightedDestUpdate(od[1]);
          } else {
            props.saveHighlightedDestUpdate(results.slice(0, 1));
          }
        });
      }
    }
  };
  useEffect(() => {
    if (props.rowSelected !== "") {
      saveSelectedRowForUpdate();
      updateCriteriaAndData();
    }
  }, [props.view]);
  const updateCriteriaAndData = () => {
    if (props.rowSelected !== "") {
      props.saveRowSelected("");
      if (props.highlightedAirlineUpdate === "") {
        //hardcoded to make XPG-QQS work without error
        //remove if else when fixed
        if (
          typeof props.highlightedOriginUpdate === "string" ||
          typeof props.highlightedDestUpdate === "string"
        ) {
          props.saveHighlightedOrigin(props.highlightedOriginUpdate);
          props.saveHighlightedDest(props.highlightedDestUpdate);
          props.saveCriteria({
            ...provisionalCriteria,
            selectedOrigin:
              typeof props.highlightedOriginUpdate === "string"
                ? ""
                : props.highlightedOriginUpdate[0][1],
            selectedDestination:
              typeof props.highlightedDestUpdate === "string"
                ? ""
                : props.highlightedDestUpdate[0][1],
            highlightedOnd:
              (typeof props.highlightedOriginUpdate === "string"
                ? props.highlightedOriginUpdate
                : props.highlightedOriginUpdate[0][0].slice(0, 3)) +
              "-" +
              (typeof props.highlightedDestUpdate === "string"
                ? props.highlightedDestUpdate
                : props.highlightedDestUpdate[0][0].slice(0, 3)),
          });
          setprovisionalCriteria((prev) => ({
            ...prev,
            selectedOrigin:
              typeof props.highlightedOriginUpdate === "string"
                ? ""
                : props.highlightedOriginUpdate[0][1],
            selectedDestination:
              typeof props.highlightedDestUpdate === "string"
                ? ""
                : props.highlightedDestUpdate[0][1],
          }));
          setCriteria({
            ...provisionalCriteria,
            selectedOrigin:
              typeof props.highlightedOriginUpdate === "string"
                ? ""
                : props.highlightedOriginUpdate[0][1],
            selectedDestination:
              typeof props.highlightedDestUpdate === "string"
                ? ""
                : props.highlightedDestUpdate[0][1],
          });
        } else {
          props.saveHighlightedOrigin(props.highlightedOriginUpdate[0][0]);
          props.saveHighlightedDest(props.highlightedDestUpdate[0][0]);
          props.saveCriteria({
            ...provisionalCriteria,
            selectedOrigin: props.highlightedOriginUpdate[0][1],
            selectedDestination: props.highlightedDestUpdate[0][1],
          });
          setprovisionalCriteria((prev) => ({
            ...prev,
            selectedOrigin: props.highlightedOriginUpdate[0][1],
            selectedDestination: props.highlightedDestUpdate[0][1],
          }));
          setCriteria({
            ...provisionalCriteria,
            selectedOrigin: props.highlightedOriginUpdate[0][1],
            selectedDestination: props.highlightedDestUpdate[0][1],
          });
        }
      } else {
        //hardcoded
        //remove if block and make only else run when fixed
        if (typeof props.highlightedAirlineUpdate === "string") {
          props.saveHighlightedAirline(props.highlightedAirlineUpdate);
          props.saveCriteria({
            ...provisionalCriteria,
            marketingCarrier: "",
            highlightedMarketingCarrier: props.highlightedAirlineUpdate,
          });
          setprovisionalCriteria((prev) => ({
            ...prev,
            marketingCarrier: "",
          }));
          setCriteria({
            ...provisionalCriteria,
            marketingCarrier: "",
          });
        } else {
          props.saveHighlightedAirline(props.highlightedAirlineUpdate[0][0]);
          props.saveCriteria({
            ...provisionalCriteria,
            marketingCarrier: props.highlightedAirlineUpdate[0][1],
          });
          setprovisionalCriteria((prev) => ({
            ...prev,
            marketingCarrier: props.highlightedAirlineUpdate[0][1],
          }));
          setCriteria({
            ...provisionalCriteria,
            marketingCarrier: props.highlightedAirlineUpdate[0][1],
          });
        }
      }
      props.saveHighlightedAirlineUpdate("");
      props.saveHighlightedDestUpdate("");
      props.saveHighlightedOriginUpdate("");
    } else {
      props.saveCriteria(provisionalCriteria);
      setCriteria(provisionalCriteria);
    }
  };
  useEffect(() => {
    if (props.initial) {
      loader();
    }
  }, [props.load]);

  const loader = () => {
    if (props.load) {
      props.setUpdateLoading(true);
      document.getElementById(
        "loader-blur"
      ).style.cssText = `transition: opacity 1s;  
    opacity: 0.2; 
    pointer-events: none;
    `;
      document.getElementById(
        "filters-unclickable"
      ).style.cssText = `pointer-events: none;`;
      document.getElementById(
        "tabs-unclickable"
      ).style.cssText = `pointer-events: none;`;
    } else {
      document.getElementById(
        "loader-blur"
      ).style.cssText = `transition: opacity 1s;  
      opacity: 1;
      pointer-events: auto; `;
      document.getElementById(
        "filters-unclickable"
      ).style.cssText = `pointer-events: auto;`;
      props.setUpdateLoading(false);
      document.getElementById(
        "tabs-unclickable"
      ).style.cssText = `pointer-events: auto;`;
    }
  };
  const resetFilters = () => {
    setprovisionalCriteria(() => ({
      selectedOrigin: "",
      selectedDestination: "",
      selectedDate: {
        year: String(new Date().getFullYear()),
        month: String(new Date().getMonth() + 1),
        specificDate: "",
        week: "",
        quarter: "",
      },
      period: "fullPeriod",
      dateType: "booking",
      periodComparisonType: "samePeriodPreviousYear",
      pointOfSale: "",
      market: "",
      marketingCarrier: "",
      highlightedmarketingCarrier: undefined,
      highlightedOnd: undefined,
      cabinClass: "",
      sortBy: "travel_agency",
    }));
    props.saveRowSelected("");
    props.saveHighlightedDest("");
    props.saveHighlightedAirline("");
    props.saveHighlightedOrigin("");

    //for updating when selected
    if (
      props.criteria.highlightedOnd !== undefined ||
      props.criteria.highlightedMarketingCarrier !== undefined
    ) {
      props.saveCriteria({
        ...props.criteria,
        highlightedOnd: undefined,
        highlightedMarketingCarrier: undefined,
      });
    }
  };

  return (
    <div id="filters-unclickable">
      <div className="row separacionSuperior">
        {/* Origin and destination */}
        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 alignLeft">
          <div className="row">
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">
              <div className="blueFont OandD">O&amp;D</div>
            </div>
            <div className="col-xs-11 col-sm-7 col-md-7 col-lg-7 alignLeft rightPadding10px">
              <div className="input-group input-group-sm">
                <span className="input-group-addon">From:</span>

                <FilterInput
                  placeholder="Country, city or airport"
                  objName={"selectedOrigin"}
                  property={provisionalCriteria.selectedOrigin}
                  updateData={setprovisionalCriteria}
                  updateCriteria={setCriteria}
                />

                <span className="input-group-addon">To:</span>

                <FilterInput
                  placeholder="Country, city or airport"
                  objName={"selectedDestination"}
                  property={provisionalCriteria.selectedDestination}
                  updateData={setprovisionalCriteria}
                  updateCriteria={setCriteria}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 alignLeft leftPadding5px">
              <div className="input-group input-group-sm">
                <span className="input-group-addon">Marketing Carrier:</span>

                <FilterInput
                  placeholder="Airline name/code"
                  objName={"marketingCarrier"}
                  property={provisionalCriteria.marketingCarrier}
                  updateData={setprovisionalCriteria}
                  updateCriteria={setCriteria}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Origin and destination */}
        {/* More Filters */}

        <MoreFilters
          market={provisionalCriteria.market}
          pointOfSale={provisionalCriteria.pointOfSale}
          period={provisionalCriteria.period}
          provisional={provisionalCriteria}
          periodComparisonType={provisionalCriteria.periodComparisonType}
          updateData={setprovisionalCriteria}
          cabinClass={provisionalCriteria.cabinClass}
          disallowPreviousPeriodSelection={disallowPreviousPeriodSelection}
          disallowPeriodToDateSelection={disallowPeriodToDateSelection}
        />
        {/* /More Filters */}
      </div>
      {/* Date Selection */}
      <div className="row separacionSuperior">
        <DateSelector
          dateType={provisionalCriteria.dateType}
          updateProvisional={setprovisionalCriteria}
          provisional={provisionalCriteria}
        />
      </div>

      {/* /Date Selection */}
      {/* Booking Date and Departure Date */}
      <div className="row leftPadding5px ">
        <div className="col-lg-5 col-md-5 col-sm-7 col-xs-8 bottomShadow thirdrowfilterspadding">
          <div className="radio-filter-line">
            <label className="radio-inline">
              <input
                type="radio"
                value="booking"
                checked={provisionalCriteria.dateType === "booking"}
                name="dateType"
                onChange={() => {
                  setprovisionalCriteria((prev) => ({
                    ...prev,
                    dateType: "booking",
                  }));
                }}
              />
              <span className="blueFont">Booking Date</span>
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                value="departure"
                name="dateType"
                checked={provisionalCriteria.dateType === "departure"}
                onChange={() =>
                  setprovisionalCriteria((prev) => ({
                    ...prev,
                    dateType: "departure",
                  }))
                }
              />
              <span className="blueFont">Departure Date</span>
            </label>
            <a className="resetFilterLink handCursor" onClick={resetFilters}>
              Reset all filters
            </a>
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-5 col-xs-4 leftPadding00">
          <div className="diagonalShadow"></div>
          <div className="paddingTopShadow">
            <div className="topShadow"></div>
          </div>
          <div
            className="updateButton"
            hidden={showUpdate ? "" : "hideen"}
            onClick={updateCriteriaAndData}
          >
            Update
          </div>
        </div>
      </div>

      {props.updateLoading && <Loader label="Loading . . ." />}
      {/* /Booking Date and Departure Date */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    updateLoading: state.app && state.app.updateLoading,
    criteria: state.app && state.app.criteria,
    view: state.app && state.app.view,

    rowSelected: state.app && state.app.rowSelected,
    highlightedDestUpdate: state.app && state.app.highlightedDestUpdate,
    highlightedOriginUpdate: state.app && state.app.highlightedOriginUpdate,
    highlightedAirlineUpdate: state.app && state.app.highlightedAirlineUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateLoading: (bool) => dispatch(setUpdateLoading(bool)),
    saveCriteria: (criteria) => dispatch(saveCriteria(criteria)),
    saveRowSelected: (rowSelected) => dispatch(saveRowSelected(rowSelected)),
    saveHighlightedOrigin: (from) => dispatch(saveHighlightedOrigin(from)),
    saveHighlightedDest: (to) => dispatch(saveHighlightedDest(to)),
    saveHighlightedAirline: (airline) =>
      dispatch(saveHighlightedAirline(airline)),
    saveHighlightedOriginUpdate: (from) =>
      dispatch(saveHighlightedOriginUpdate(from)),
    saveHighlightedDestUpdate: (to) => dispatch(saveHighlightedDestUpdate(to)),
    saveHighlightedAirlineUpdate: (airline) =>
      dispatch(saveHighlightedAirlineUpdate(airline)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filters);
