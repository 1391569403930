import airplane from "../../assets/images/airplane.png";
import country from "../../assets/images/country.png";
import "./tabs.css";

import "../../amadeus.css";
import { useEffect, useState } from "react";
import {
  saveView,
  saveRowSelected,
  saveCriteria,
  saveHighlightedAirlineUpdate,
  saveHighlightedDestUpdate,
  saveHighlightedOriginUpdate,
  setUpdateLoading,
} from '../../redux/App/app.actions';
import { connect } from "react-redux";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Table from "../table/table";
import BookingService from "../../utilities/bookingService";
import Filters from "../filters/filters";
import Loader from "../loader/spinner";

//Load Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

function Tabs(props) {
  const [tabView, setTabView] = useState("airlines");

  const [tableData, setTableData] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    updateTableData();
  }, [props.criteria]);
  useEffect(() => {
    updateTableData();
  }, [props.updateLoading===true]);
  useEffect(() => {
    updateTableData();
  }, [props.view]);
  const [onclickRowSelected, setonclickRowSelected] = useState(false);

  return (
    <>
      <div className="ViewContainer">
        <div className="container">
          <div className="totalContainer">
            <div className="leftBackgroundBorder"></div>
            <div className="rightBackgroundBorder"></div>
            <div className="headerBackgroundGray" id="tabs-unclickable">
              <div className="row">
                <div className="col-xs-8 col-lg-8 alignLeft headerPadding">
                  <span className="amadeusSubtitleFont">
                    {props.view === "onds"
                      ? "Origins & Destinations"
                      : "Airlines View"}
                  </span>
                </div>
                <div className="col-xs-4 col-lg-4 alignRight headerPadding hideOverflow">
                  <div className="tabs">
                    <div
                      className={
                        props.view === "airlines"
                          ? "tabActive handCursor"
                          : "tabUnactive handCursor"
                      }
                      onClick={() => {
                        props.saveView("airlines");
                        setonclickRowSelected(true);
                      }}
                    >
                      <img
                        alt="Airlines"
                        className="handCursor"
                        height="25"
                        src={airplane}
                        title="Airlines"
                        width="25"
                      ></img>
                      <span>Airlines View</span>
                    </div>
                    <div
                      className={
                        props.view === "airlines"
                          ? "tabUnactive handCursor"
                          : "tabActive handCursor"
                      }
                      onClick={() => {
                        props.saveView("onds");
                        setonclickRowSelected(true);
                      }}
                    >
                      <img
                        alt="O&amp;D"
                        className="handCursor"
                        height="25"
                        src={country}
                        title="Origins and Destinations"
                        width="25"
                      ></img>
                      <span>O&amp;D View</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mainBackground">
              <Filters
                load={loader}
                initial={
                  tableData && tableData.dataListReady === true ? true : false
                }
              />

              {/* <!-- Report --> */}
              {tableData && tableData.dataListReady === true && (
                <>
                  <div
                    className="row separacionSuperior separacionInferior"
                    id="loader-blur"
                  >
                    <div>
                      <Table props={tableData}></Table>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="grayBorder">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={tableData.evolutionData}
                        />
                      </div>

                      <div className="separador"></div>
                      <div className="grayBorder">
                        <HighchartsReact
                          id="grayBorder1"
                          highcharts={Highcharts}
                          options={tableData.pieData}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!(tableData && tableData.dataListReady) && (
                <Loader label="Initializing table and charts..."></Loader>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  async function updateTableData() {
    setLoader(true);
    let apiCall =
      await props.view === "onds"
        ? BookingService().getTopOnds(props.criteria)
        : BookingService().getTopAirlines(props.criteria);
    apiCall
      .then((data) => {
        BookingService()
          .getTotalPerAgency(props.criteria)
          .then((dataTotal) => {
            BookingService()
              .getEvolution(props.criteria)
              .then((evolutionData) => {
                props.view === "onds"
                  ? BookingService()
                      .getTopAirlinesPie(props.criteria, dataTotal)
                      .then((pieData) => {
                        setTableData(
                          props.rowSelected && !onclickRowSelected
                            ? {
                                ...tableData,
                                pieData,
                                evolutionData,
                              }
                            : {
                                ...tableData,
                                dataList: data,
                                dataListReady: true,
                                dataListTotal: dataTotal,
                                pieData,
                                evolutionData,
                              },
                          setLoader(false),
                          props.setUpdateLoading(false)
                        );
                        setonclickRowSelected(false);
                      })
                  : BookingService()
                      .getTopOndsPie(props.criteria, dataTotal)
                      .then((pieData) => {
                        setTableData(
                          props.rowSelected && !onclickRowSelected
                            ? {
                                ...tableData,
                                pieData,
                                evolutionData,
                              }
                            : {
                                ...tableData,
                                dataList: data,
                                dataListReady: true,
                                dataListTotal: dataTotal,
                                pieData,
                                evolutionData,
                              },
                          setLoader(false),
                          props.setUpdateLoading(false)
                        );
                        setonclickRowSelected(false);
                      });
              });
          });
      })
      .catch((data) => {
        console.error("Error at update: ", data);
      })
      .finally(() => {});
  }
}

const mapStateToProps = (state) => {
  return {
    updateLoading: state.app && state.app.updateLoading,
    view: state.app && state.app.view,
    criteria: state.app && state.app.criteria,
    rowSelected: state.app && state.app.rowSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveView: (view) => dispatch(saveView(view)),
    setUpdateLoading: (bool) => dispatch(setUpdateLoading(bool)),

    saveCriteria: (criteria) => dispatch(saveCriteria(criteria)),
    saveRowSelected: (rowSelected) => dispatch(saveRowSelected(rowSelected)),
    saveHighlightedOriginUpdate: (from) =>
      dispatch(saveHighlightedOriginUpdate(from)),
    saveHighlightedDestUpdate: (to) => dispatch(saveHighlightedDestUpdate(to)),
    saveHighlightedAirlineUpdate: (airline) =>
      dispatch(saveHighlightedAirlineUpdate(airline)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
