import * as React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import "./spinner.css";
import { Triangle } from "react-loader-spinner";

export default function Loader(props) {
  const stackTokens = {
    childrenGap: 20,
    maxWidth: 250,
  };
  if (props.label === "Initializing table and charts...") {
    document.getElementById("update-charts").style.cssText = `
    margin-top: 15%;
    margin-left: 35%;
  `;
  }

  return (
    <Stack tokens={stackTokens} className="max-width" id="update-charts">
      <div className="loader">
        <Triangle
          id="tri"
          height="150"
          width="150"
          color="#0e7fc3"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>

      <label className="loader-label" htmlFor="tri">
        {props.label}
      </label>
    </Stack>
  );
}
