import apiFetch from "../main";
import bookingCriteriaFactory from "./bookingCriteriaFactory";
import parsingUtilsFactory from "./parseUtilityFactory";

function BookingService() {
  return {
    getTopAirlines: function (criteria) {
      return apiFetch({
        url: "/booking_agency_airlines",
        dataWS: true,
        params: bookingCriteriaFactory().criteriaToHttpParams(criteria, true),
      }).then((data) => parsingUtilsFactory().getTopAirlines(data));
    },

    getTopAirlinesPie: function (criteria, travelAgencyTotal) {
      return apiFetch({
        url: "/booking_agency_airlines",
        dataWS: true,
        params: bookingCriteriaFactory().criteriaToHttpParams(criteria, true),
      }).then((data) =>
        parsingUtilsFactory().getAirlinesPieChartData(data, travelAgencyTotal)
      );
    },
    getTopOnds: function (criteria) {
      return apiFetch({
        url: "/booking_agency_onds",
        dataWS: true,
        params: bookingCriteriaFactory().criteriaToHttpParams(criteria, true),
      }).then((data) => parsingUtilsFactory().getTopOnds(data));
    },
    getTopOndsPie: function (criteria, travelAgencyTotal) {
      return apiFetch({
        url: "/booking_agency_onds",
        dataWS: true,
        params: bookingCriteriaFactory().criteriaToHttpParams(criteria, true),
      }).then((data) =>
        parsingUtilsFactory().getOndsPieChartData(data, travelAgencyTotal)
      );
    },
    getEvolution: function (criteria) {
      return apiFetch({
        url: "/booking_agency_evolutions",
        dataWS: true,
        params: bookingCriteriaFactory().criteriaToHttpParams(criteria, false),
      }).then((data) => parsingUtilsFactory().getEvolutionData(data));
    },
    getTotalPerAgency: function (criteria) {
      return apiFetch({
        url: "/booking_agency_totals",
        dataWS: true,
        params: bookingCriteriaFactory().criteriaToHttpParams(criteria, true),
      }).then((data) => parsingUtilsFactory().getTotalsPerAgency(data));
    },
  };
}
export default BookingService;
