// eslint-disable-next-line
import { env } from '.';
import "./App.css";
import { connect } from "react-redux";
import Header from "./components/header/header";
import { useEffect, useLayoutEffect, useState } from "react";

import {
  getAvailableAccount,
  getAvailableAccounts,
  setUpdateLoading,
} from './redux/App/app.actions';

import Loader from "./components/loader/spinner";
import apiFetch from "./main";
import store from './redux/store';

function App(props) {
 
  const [state, setState] = useState({
    authenticated: false,
    activeAccount: undefined,
    availableAccounts: [],
  });
  useEffect(() => {
    if (props && props.userSettings && props.userSettings.token) {
      initializeUserInfo();
    }
     if (props && props.userSettings && env.legacy===false) {
       initializeUserInfo();
     }
  }, [props.userSettings]);

  useLayoutEffect(() => {
    if (props.activeAccount && props.availableAccounts) {
      let s = {
        ...state,
        email: props.userSettings.user.email,
        activeAccount: getActiveAccountFromUser(props.preferences,props.activeAccount),
        availableAccounts: props.availableAccounts,
      };
      setState(s);
    }
  }, [props.activeAccount, props.availableAccounts]);
  useLayoutEffect(() => {
    if (props.activeAccount) {     

      let s = {
        ...state,
        email: props.userSettings.user.email,
        activeAccount: getActiveAccountFromUser(props.preferences,props.activeAccount),
        availableAccounts: props.availableAccounts,
      };
      setState(s);
    }
  }, [props.activeAccount !== state.activeAccount]);

  return (
    <div className="App">
      {state.authenticated || state.activeAccount ? (
        <Header
          initializeUserInfo={initializeUserInfo}
          activeAccount={state.activeAccount}
          availableAccounts={state.availableAccounts}
          email={state.email}
        ></Header>
      ) : (
        <Loader label="Initializing the application..."></Loader>
      )}
    </div>
  );

  function initializeUserInfo(changedAccount) {
    apiFetch({
      url: '/user-data/preferences',
      
    }).then((res) => {
      store.dispatch({
        type: 'GETPREFERENCES',
        payLoad: res,
      });
      props.getAvailableAccount().then((res) => {
        props.getAvailableAccounts().then((r) =>
          setState({
            ...state,
            authenticated: true,
          })
        );
      });
    })
    if (changedAccount === true) { 
    props.setUpdateLoading(true)
    }
  }

  function getActiveAccountFromUser(preferences,activeAccount) { 
    let userAccount = preferences.booking_analysis_account;

    return userAccount&&userAccount !== activeAccount ? userAccount : activeAccount;
  }
}
const mapStateToProps = (state) => {
  return {
    userSettings: state.app && state.app.userSettings,
    updateLoading: state.app && state.app.updateLoading,
    preferences: state.app && state.app.preferences,
    activeAccount:
      state.app &&
      state.app.availableAccount &&
      state.app.availableAccount.name,
    availableAccounts: state.app && state.app.availableAccounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateLoading: (bool) => dispatch(setUpdateLoading(bool)),
    getAvailableAccount: () => dispatch(getAvailableAccount()),
    getAvailableAccounts: () => dispatch(getAvailableAccounts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
