import apiFetch from "../main";

export default function CommonService() {
  return {
    getAirportsTypeAhead: function (q) {
      return apiFetch({
        url: "/por_searches",
        params: { q },
      })
        .then(({ linked, por_search_hits }) => {
          const locations = {};
          const results = [];
          let location;

          for (let i = 0; i < linked.airports.length; i++) {
            location = linked.airports[i];
            location.type = "airport";
            location.links = undefined;
            locations["airport:" + location.id] = location;
          }
          for (let i = 0; i < linked.cities.length; i++) {
            location = linked.cities[i];
            location.type = "city";
            location.links = undefined;
            locations["city:" + location.id] = location;
          }
          for (let i = 0; i < linked.countries.length; i++) {
            location = linked.countries[i];
            location.type = "country";
            location.links = undefined;
            locations["country:" + location.id] = location;
          }
          for (let i = 0; i < por_search_hits.length; i++) {
            for (const type in por_search_hits[i].links) {
              const code = por_search_hits[i].links[type];

              if (type + ":" + code in locations) {
                results.push(locations[type + ":" + code]);
              }
            }
          }

          return results;
        })
        .catch((e) => {
          console.error("Error retrieving location data", e);
          return [];
        });
    },
    getMarketingCarriersTypeAhead: function (q) {
      return apiFetch({
        url: "/airline_searches",
        params: { q },
      })
        .then((data) => data.linked.airlines)
        .catch((e) => {
          console.error("Error retrieving marketing carriers", e);
          return [];
        });
    },

    getMarkets: function () {
      return apiFetch({
        url: "/booking_market_searches",
        dataWS: true,
        cache: "force-cache",
      }).then((data) =>
        data.linked.booking_markets.map(({ links, ...market }) => market)
      );
    },

    getPointsOfSale: function () {
      return apiFetch({
        url: "/pos_searches",
        dataWS: true,
        cache: "force-cache",
      }).then((data) => data.linked.pos.map(({ links, ...pos }) => pos));
    },
    getCabinClasses: function () {
      return [
        {
          id: "",
          name: "All",
        },
        {
          id: "F",
          name: "First Class",
        },
        {
          id: "C",
          name: "Business Class",
        },
        {
          id: "W",
          name: "Premium Economy",
        },
        {
          id: "Y",
          name: "Economy",
        },
      ];
    },
  };
}
