import React, { useEffect, useState } from "react";

import filtersUrl from "../../assets/images/filters.png";
import CommonService from "../../utilities/commonService";
import FilterInput from "../filterinputs/filterinput";
import "./morefilters.css";
function MoreFilters(props) {
  const [selectedFullPeriod, setSelectedFullPeriod] = useState(true);
  const [selectedSamePeriodPrevYear, setSelectedSamePeriodPrevYear] =
    useState(true);
  useEffect(() => {
    if (props.period === "fullPeriod") {
      setSelectedFullPeriod(true);
    } else {
      setSelectedFullPeriod(false);
    }
  }, [props.period]);
  useEffect(() => {
    if (props.periodComparisonType === "samePeriodPreviousYear") {
      setSelectedSamePeriodPrevYear(true);
    } else {
      setSelectedSamePeriodPrevYear(false);
    }
  }, [props.periodComparisonType]);
  const [cabinClasses, setCabinClasses] = useState([]);
  const retrieveCabinClasses = () => {
    const items = CommonService().getCabinClasses();

    const ar = items.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });
    setCabinClasses(ar);
  };
  useEffect(() => {
    retrieveCabinClasses();
  }, []);

  const [moreFilters, setMoreFilters] = useState(false);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);
  const handleClickOutside = (e) => {
    const mostrarPopUp = document.getElementById("mostrarPopUp");
    const moreFiltersButton = document.getElementById("moreFiltersButton");

    if (
      mostrarPopUp &&
      !mostrarPopUp.contains(e.target) &&
      !moreFiltersButton.contains(e.target)
    ) {
      setMoreFilters(false);
    }
  };
  return (
    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 alignRight">
      <button
        className="btn btn-default blueFont"
        id="moreFiltersButton"
        onMouseDown={() => setMoreFilters((prev) => !prev)}
      >
        More Filters&nbsp;
        <img
          alt="Filters"
          height="24"
          src={filtersUrl}
          title="Show more filters"
          width="20"
        />
      </button>

      {/* Popup panel with more filters */}

      {moreFilters && (
        <div id="mostrarPopUp">
          <div className="comparisionPeriod alignLeft filtersPopUpWindow ">
            <div className="row filtersPopUpWindow">
              <div className="col-sm-12 col-md-12 col-lg-12 extraHeight filtersPopUpWindow">
                <span className="titlePeriod filtersPopUpWindow">
                  Period Comparison
                </span>
              </div>
            </div>
            <div className="row filtersPopUpWindow">
              <div className="col-sm-12 col-md-7 col-lg-7 rightBorderSeparator filtersPopUpWindow">
                <label>
                  <input
                    type="radio"
                    className="filtersPopUpWindow"
                    id="prevPer"
                    checked={!selectedSamePeriodPrevYear}
                    value="previousPeriod"
                    disabled={props.disallowPreviousPeriodSelection}
                    onChange={() => {
                      props.updateData((prev) => ({
                        ...prev,
                        periodComparisonType: "previousPeriod",
                      }));
                    }}
                  />
                  <span className="fuenteGris filtersPopUpWindow">
                    Previous period
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    id="samePerPrevYear"
                    className="filtersPopUpWindow"
                    checked={selectedSamePeriodPrevYear}
                    onChange={() => {
                      props.updateData((prev) => ({
                        ...prev,
                        periodComparisonType: "samePeriodPreviousYear",
                      }));
                    }}
                    value="samePeriodPreviousYear"
                  />
                  <span className="fuenteGris filtersPopUpWindow">
                    Same period prev. year
                  </span>
                </label>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 leftBorderSeparator filtersPopUpWindow">
                <label>
                  <input
                    type="radio"
                    className="filtersPopUpWindow"
                    id="fullPer"
                    value="fullPeriod"
                    checked={selectedFullPeriod}
                    onChange={() => {
                      props.updateData((prev) => ({
                        ...prev,
                        period: "fullPeriod",
                      }));
                    }}
                  />
                  <span className="fuenteGris filtersPopUpWindow">
                    Full period
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    className="filtersPopUpWindow"
                    id="perToDate"
                    value="periodToDate"
                    disabled={props.disallowPeriodToDateSelection}
                    checked={!selectedFullPeriod}
                    onChange={() => {
                      props.updateData((prev) => ({
                        ...prev,
                        period: "perToDate",
                      }));
                    }}
                  />
                  <span className="fuenteGris filtersPopUpWindow">
                    Period to date
                  </span>
                </label>
              </div>
            </div>
            <div className="row filtersPopUpWindow">
              <div className="col-sm-12 col-md-12 col-lg-12 filtersPopUpWindow">
                <div className="grayLine filtersPopUpWindow"></div>
              </div>
            </div>
            <div className="row filtersPopUpWindow">
              <div className="col-sm-12 col-md-12 col-lg-12 filtersPopUpWindow">
                <span className="filtersPopUpWindow">POS:</span>
                <FilterInput
                  placeholder="Office id"
                  objName={"pointOfSale"}
                  property={props.pointOfSale}
                  updateData={props.updateData}
                />
              </div>
            </div>
            <div className="row filtersPopUpWindow">
              <div className="col-sm-12 col-md-6 col-lg-6 filtersPopUpWindow">
                <span className="filtersPopUpWindow">Market:</span>
                <FilterInput
                  placeholder="Country name/code"
                  objName={"market"}
                  property={props.market}
                  updateData={props.updateData}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 filtersPopUpWindow">
                <span className="filtersPopUpWindow">Cabin Class:</span>
                <select
                  className="input-sm form-control filtersPopUpWindow"
                  onChange={(e) =>
                    props.updateData((prev) => ({
                      ...prev,
                      cabinClass: e.target.value,
                    }))
                  }
                  value={props.cabinClass}
                >
                  {cabinClasses}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MoreFilters;
