import JSONstat from "jsonstat";

function parsingUtilsFactory() {
  return {
    getEvolutionData: function (data) {
      var dataset = {};
      var dates = {};
      var travel_agency;
      var competition;
      var market;

      try {
        if (JSONstat(data).Dataset("booking_evolution")) {
          dataset = JSONstat(data).Dataset("booking_evolution");
        }

        /* Lets try booking_date */
        var dateDimension = dataset.Dimension("booking_date");
        var dateDimensionName = "booking_date";

        if (dateDimension === null) {
          /* If its null, try departure_date */
          dateDimension = dataset.Dimension("departure_date");
          dateDimensionName = "departure_date";
        }
        if (dateDimension === null) {
          /* If its null, try booking_month */
          dateDimension = dataset.Dimension("booking_month");
          dateDimensionName = "booking_month";
        }
        if (dateDimension === null) {
          /* If its null, try departure_month */
          dateDimension = dataset.Dimension("departure_month");
          dateDimensionName = "departure_month";
        }
        dates = dateDimension.id;

        travel_agency = {
          name: "travel_agency",
          data: [],
          color: "#0e0091",
          legendIndex: 1,
          marker: {
            symbol: "circle",
          },
        };
        competition = {
          name: "competition",
          data: [],
          color: "#fc932a",
          legendIndex: 2,
          marker: {
            symbol: "square",
          },
        };
        market = {
          name: "market",
          data: [],
          color: "#d81367",
          legendIndex: 3,
          marker: {
            symbol: "triangle",
          },
        };
        var tuple;
        for (var i = 0; i < dates.length; i++) {
          tuple = {};
          tuple[dateDimensionName] = dates[i];
          tuple.agency_type = "travel_agency";
          travel_agency.data.push(dataset.Data(tuple).value);
          tuple = {};
          tuple[dateDimensionName] = dates[i];
          tuple.agency_type = "competition";
          competition.data.push(dataset.Data(tuple).value);
          tuple = {};
          tuple[dateDimensionName] = dates[i];
          tuple.agency_type = "market";
          market.data.push(dataset.Data(tuple).value);
        }
      } catch (e) {
        console.error("getEvolutionData error " + e);
        throw e;
      }

      return {
        credits: {
          enabled: false,
        },
        title: {
          text: "Evolution",
        },
        options: {
          chart: {
            type: "line",
            animation: false,
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                ],
              },
            },
          },
          plotOptions: {
            series: {
              animation: false,
              marker: {
                enabled: true,
              },
            },
          },
        },
        yAxis: {
          title: {
            text: "Values",
            style: {
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          title: "Date",
          categories: dates,
          labels: {
            overflow: "justify",
            enabled: true,
            rotation: -30,
            y: 15,
            align: "right",
          },
          tickWidth: 1,
          tickLength: 5,
          tickInterval: Math.floor(dates.length / 5),
          tickPixelInterval: 80,
          tickmarkPlacement: "on",
        },
        series: [travel_agency, competition, market],
      };
    },
    getOndsPieChartData: function (data, travelAgencyTotal) {
      var dataset;
      var onds;
      var pieSeries = [];
      var colorArray = [
        "#0e7fc3",
        "#d81367",
        "#fc932a",
        "#fb9399",
        "#ff0091",
        "#0e0091",
      ];

      try {
        var periodsDim;
        var periods;
        var periodsDimName = "";

        if (JSONstat(data).Dataset("top_onds")) {
          dataset = JSONstat(data).Dataset("top_onds");
        }
        if (dataset.Dimension("origin_destination")) {
          onds = dataset.Dimension("origin_destination").id;
        }

        /* Lets try booking_period */
        periodsDim = dataset.Dimension("booking_period");
        periodsDimName = "booking_period";

        if (periodsDim === null) {
          /* If its null, try departure_period */
          periodsDim = dataset.Dimension("departure_period");
          periodsDimName = "departure_period";
        }

        periods = periodsDim.id;

        var minimum = function (value1, value2) {
          return value1 < value2 ? value1 : value2;
        };

        var yValue = 0;
        var itemData;
        var tuple;
        for (var i = 0; i < minimum(onds.length, 5); i++) {
          tuple = { origin_destination: onds[i], agency_type: "travel_agency" };
          tuple[periodsDimName] = periods[1];
          itemData = dataset.Data(tuple);
          yValue = Array.isArray(itemData) ? itemData[0].value : itemData.value;

          pieSeries.push({
            color: colorArray[i],
            name: onds[i],
            sliced: false,
            y: yValue,
          });
        }
      } catch (e) {
        console.error("GetOndsPieChart error " + e);
        throw e;
      }

      const total = pieSeries.reduce((acc, x) => acc + x.y, 0);

      return {
        credits: {
          enabled: false,
        },
        title: {
          text: "Top Origins & Destinations",
        },
        options: {
          chart: {
            animation: false,
            type: "pie",
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                ],
              },
            },
          },
          plotOptions: {
            series: {
              animation: false,
              allowPointSelect: false,
            },
          },
        },
        series: [
          {
            allowPointSelect: false,
            name: "Bookings",
            data: pieSeries,
            innerSize: "40%",
            type: "pie",
            borderWidth: total ? 1 : 0,
          },
        ],
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
      };
    },
    getAirlinesPieChartData: function (data, travelAgencyTotal) {
      var dataset;
      var marketingCarriers;
      var pieSeries = [];
      var colorArray = [
        "#0e7fc3",
        "#d81367",
        "#fc9369",
        "#fb9399",
        "#ff0091",
        "#0e0091",
      ];

      try {
        var periodsDim;
        var periods;
        var periodsDimName = "";

        if (JSONstat(data).Dataset("top_airlines")) {
          dataset = JSONstat(data).Dataset("top_airlines");
        }
        if (dataset.Dimension("marketing_carrier")) {
          marketingCarriers = dataset.Dimension("marketing_carrier").id;
        }

        /* Lets try booking_period */
        periodsDim = dataset.Dimension("booking_period");
        periodsDimName = "booking_period";

        if (periodsDim === null) {
          /* If its null, try departure_period */
          periodsDim = dataset.Dimension("departure_period");
          periodsDimName = "departure_period";
        }

        periods = periodsDim.id;

        var minimum = function (value1, value2) {
          return value1 < value2 ? value1 : value2;
        };

        var yValue = 0;
        var itemData;
        var tuple;
        for (var i = 0; i < minimum(marketingCarriers.length, 5); i++) {
          tuple = {
            marketing_carrier: marketingCarriers[i],
            agency_type: "travel_agency",
          };
          tuple[periodsDimName] = periods[1];
          itemData = dataset.Data(tuple);
          yValue = Array.isArray(itemData) ? itemData[0].value : itemData.value;

          pieSeries.push({
            color: colorArray[i],
            name: marketingCarriers[i],
            sliced: false,
            y: yValue,
          });
        }
      } catch (e) {
        console.error("getAirlinesPieChartData error " + e);
        throw e;
      }

      const total = pieSeries.reduce((acc, x) => acc + x.y, 0);

      return {
        credits: {
          enabled: false,
        },
        title: {
          text: "Top Airlines",
        },
        options: {
          chart: {
            animation: false,
          },
          plotOptions: {
            series: {
              animation: false,
            },
          },
          exporting: {
            accessibility: {
              enabled: true,
            },
            buttons: {
              contextButton: {
                menuItems: [
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                ],
              },
            },
          },
        },
        series: [
          {
            allowPointSelect: false,
            name: "Bookings",
            data: pieSeries,
            innerSize: "40%",
            type: "pie",
            borderWidth: total ? 1 : 0,
          },
        ],
        // tooltip: {
        //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        // },
      };
    },
    getTotalsPerAgency: function (data) {
      var jsonStat;
      var dataset;
      var parsedData;
      var periods;
      var periodsDimensionName;
      var travelAgencyBookings,
        competitionBookings,
        marketBookings,
        travelAgencyBookingsPrevPeriod,
        competitionBookingsPrevPeriod,
        marketBookingsPrevPeriod;
      try {
        jsonStat = JSONstat(data);
        if (jsonStat && jsonStat.Dataset("total_per_agency_type")) {
          dataset = jsonStat.Dataset("total_per_agency_type");
        }
        periodsDimensionName = "booking_period";
        periods =
          dataset.Dimension(periodsDimensionName) !== null
            ? dataset.Dimension(periodsDimensionName).id
            : undefined;

        if (!periods) {
          periodsDimensionName = "departure_period";
          periods =
            dataset.Dimension(periodsDimensionName) !== null
              ? dataset.Dimension(periodsDimensionName).id
              : undefined;
        }

        if (periods) {
          if (periods.length === 1) {
            // The data do not include period comparison
            travelAgencyBookings = this.getDataSettingTuple(
              dataset,
              { agency_type: "travel_agency" },
              periodsDimensionName,
              periods[0]
            );
            competitionBookings = this.getDataSettingTuple(
              dataset,
              { agency_type: "competition" },
              periodsDimensionName,
              periods[0]
            );
            marketBookings = this.getDataSettingTuple(
              dataset,
              { agency_type: "market" },
              periodsDimensionName,
              periods[0]
            );
          } else if (periods.length === 2) {
            // The data includes period comparison
            travelAgencyBookings = this.getDataSettingTuple(
              dataset,
              { agency_type: "travel_agency" },
              periodsDimensionName,
              periods[1]
            );
            competitionBookings = this.getDataSettingTuple(
              dataset,
              { agency_type: "competition" },
              periodsDimensionName,
              periods[1]
            );
            marketBookings = this.getDataSettingTuple(
              dataset,
              { agency_type: "market" },
              periodsDimensionName,
              periods[1]
            );

            travelAgencyBookingsPrevPeriod = this.getDataSettingTuple(
              dataset,
              { agency_type: "travel_agency" },
              periodsDimensionName,
              periods[0]
            );
            competitionBookingsPrevPeriod = this.getDataSettingTuple(
              dataset,
              { agency_type: "competition" },
              periodsDimensionName,
              periods[0]
            );
            marketBookingsPrevPeriod = this.getDataSettingTuple(
              dataset,
              { agency_type: "market" },
              periodsDimensionName,
              periods[0]
            );
          } else {
            console.error("Error: unexpected data structure");
          }
          parsedData = {
            agencyBookingsPrevPeriod: travelAgencyBookingsPrevPeriod,
            competitionBookingsPrevPeriod: competitionBookingsPrevPeriod,
            marketBookingsPrevPeriod: marketBookingsPrevPeriod,
            agencyBookings: travelAgencyBookings,
            competitionBookings: competitionBookings,
            marketBookings: marketBookings,
            agencyShare: (travelAgencyBookings / marketBookings) * 100,
            competitionShare: (competitionBookings / marketBookings) * 100,
            agencyIncrement: travelAgencyBookingsPrevPeriod
              ? (travelAgencyBookings / travelAgencyBookingsPrevPeriod) * 100 -
                100
              : undefined,
            competitionIncrement: competitionBookingsPrevPeriod
              ? (competitionBookings / competitionBookingsPrevPeriod) * 100 -
                100
              : undefined,
            marketIncrement: marketBookingsPrevPeriod
              ? (marketBookings / marketBookingsPrevPeriod) * 100 - 100
              : undefined,
          };
        }
      } catch (e) {
        console.error("getTotalsPerAgency error " + e);
      }
      return parsedData;
    },
    getDataSettingTuple: function (dataset, initTuple, property, value) {
      var tuple = initTuple;
      tuple[property] = value;
      var itemData = dataset.Data(tuple);
      return Array.isArray(itemData) ? itemData[0].value : itemData.value;
    },
    getTopAirlines: function (data) {
      var jsonStat = JSONstat(data);
      var dataset;
      var airlines;
      var periods;
      var periodsDimensionName;
      var airlinesList = [];
      try {
        dataset = jsonStat.Dataset("top_airlines");
        if (dataset && dataset.Dimension("marketing_carrier")) {
          airlines = dataset.Dimension("marketing_carrier").id;
        }

        periodsDimensionName = "booking_period";
        periods =
          dataset.Dimension(periodsDimensionName) !== null
            ? dataset.Dimension(periodsDimensionName).id
            : undefined;

        if (!periods) {
          periodsDimensionName = "departure_period";
          periods =
            dataset.Dimension(periodsDimensionName) !== null
              ? dataset.Dimension(periodsDimensionName).id
              : undefined;
        }

        var travelAgencyBookings,
          competitionBookings,
          marketBookings,
          travelAgencyBookingsPrevPeriod,
          competitionBookingsPrevPeriod,
          marketBookingsPrevPeriod;
        if (periods) {
          for (var i = 0; i < airlines.length; i++) {
            if (periods.length === 1) {
              // The data do not include period comparison
              travelAgencyBookings = this.getDataSettingTuple(
                dataset,
                {
                  marketing_carrier: airlines[i],
                  agency_type: "travel_agency",
                },
                periodsDimensionName,
                periods[0]
              );
              competitionBookings = this.getDataSettingTuple(
                dataset,
                { marketing_carrier: airlines[i], agency_type: "competition" },
                periodsDimensionName,
                periods[0]
              );
              marketBookings = this.getDataSettingTuple(
                dataset,
                { marketing_carrier: airlines[i], agency_type: "market" },
                periodsDimensionName,
                periods[0]
              );

              airlinesList.push({
                airline: airlines[i],
                agencyBookings: travelAgencyBookings,
                competitionBookings: competitionBookings,
                marketBookings: marketBookings,
                agencyShare: (travelAgencyBookings / marketBookings) * 100,
                competitionShare: (competitionBookings / marketBookings) * 100,
              });
            } else if (periods.length === 2) {
              travelAgencyBookings = this.getDataSettingTuple(
                dataset,
                {
                  marketing_carrier: airlines[i],
                  agency_type: "travel_agency",
                },
                periodsDimensionName,
                periods[1]
              );
              competitionBookings = this.getDataSettingTuple(
                dataset,
                { marketing_carrier: airlines[i], agency_type: "competition" },
                periodsDimensionName,
                periods[1]
              );
              marketBookings = this.getDataSettingTuple(
                dataset,
                { marketing_carrier: airlines[i], agency_type: "market" },
                periodsDimensionName,
                periods[1]
              );

              travelAgencyBookingsPrevPeriod = this.getDataSettingTuple(
                dataset,
                {
                  marketing_carrier: airlines[i],
                  agency_type: "travel_agency",
                },
                periodsDimensionName,
                periods[0]
              );
              competitionBookingsPrevPeriod = this.getDataSettingTuple(
                dataset,
                { marketing_carrier: airlines[i], agency_type: "competition" },
                periodsDimensionName,
                periods[0]
              );
              marketBookingsPrevPeriod = this.getDataSettingTuple(
                dataset,
                { marketing_carrier: airlines[i], agency_type: "market" },
                periodsDimensionName,
                periods[0]
              );

              airlinesList.push({
                airline: airlines[i],
                agencyBookings: travelAgencyBookings,
                competitionBookings: competitionBookings,
                marketBookings: marketBookings,
                agencyShare: (travelAgencyBookings / marketBookings) * 100,
                competitionShare: (competitionBookings / marketBookings) * 100,
                agencyIncrement:
                  (travelAgencyBookings / travelAgencyBookingsPrevPeriod) *
                    100 -
                  100,
                competitionIncrement:
                  (competitionBookings / competitionBookingsPrevPeriod) * 100 -
                  100,
                marketIncrement:
                  (marketBookings / marketBookingsPrevPeriod) * 100 - 100,
              });
            } else {
              console.error("Error: unexpected data structure");
            }
          }
        } else {
          var table = dataset.toTable({ type: "arrobj" });
          var newData;

          for (var n = 0; n < table.length; n = n + 3) {
            newData = {};
            newData.airline = table[n].marketing_carrier;
            newData.agencyBookings = table[n].value;
            newData.competitionBookings = table[n + 1].value;
            newData.marketBookings = table[n + 2].value;
            newData.agencyShare =
              (newData.agencyBookings / newData.marketBookings) * 100;
            newData.competitionShare =
              (newData.competitionBookings / newData.marketBookings) * 100;
            // Data to be assigned
            newData.agencyIncrement = 0;
            newData.competitionIncrement = 0;
            newData.marketIncrement = 0;
            airlinesList.push(newData);
          }
        }
      } catch (e) {
        console.error("getTopAirlines error " + e);
        throw e;
      }
      return airlinesList;
    },
    getTopOnds: function (data) {
      var jsonStat = JSONstat(data);
      var dataset;
      var ondsList = [];
      var onds;
      var periods;
      var periodsDimensionName;
      try {
        dataset = jsonStat.Dataset("top_onds");
        onds = dataset.Dimension("origin_destination").id;

        periodsDimensionName = "booking_period";
        periods =
          dataset.Dimension(periodsDimensionName) !== null
            ? dataset.Dimension(periodsDimensionName).id
            : undefined;

        if (!periods) {
          periodsDimensionName = "departure_period";
          periods =
            dataset.Dimension(periodsDimensionName) !== null
              ? dataset.Dimension(periodsDimensionName).id
              : undefined;
        }
        var travelAgencyBookings,
          competitionBookings,
          marketBookings,
          travelAgencyBookingsPrevPeriod,
          competitionBookingsPrevPeriod,
          marketBookingsPrevPeriod;
        if (periods) {
          for (var i = 0; i < onds.length; i++) {
            if (periods.length === 1) {
              // The data do not include period comparison
              travelAgencyBookings = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "travel_agency" },
                periodsDimensionName,
                periods[0]
              );
              competitionBookings = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "competition" },
                periodsDimensionName,
                periods[0]
              );
              marketBookings = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "market" },
                periodsDimensionName,
                periods[0]
              );

              ondsList.push({
                originDestination: onds[i],
                agencyBookings: travelAgencyBookings,
                competitionBookings: competitionBookings,
                marketBookings: marketBookings,
                agencyShare: (travelAgencyBookings / marketBookings) * 100,
                competitionShare: (competitionBookings / marketBookings) * 100,
              });
            } else if (periods.length === 2) {
              travelAgencyBookings = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "travel_agency" },
                periodsDimensionName,
                periods[1]
              );
              competitionBookings = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "competition" },
                periodsDimensionName,
                periods[1]
              );
              marketBookings = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "market" },
                periodsDimensionName,
                periods[1]
              );

              travelAgencyBookingsPrevPeriod = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "travel_agency" },
                periodsDimensionName,
                periods[0]
              );
              competitionBookingsPrevPeriod = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "competition" },
                periodsDimensionName,
                periods[0]
              );
              marketBookingsPrevPeriod = this.getDataSettingTuple(
                dataset,
                { origin_destination: onds[i], agency_type: "market" },
                periodsDimensionName,
                periods[0]
              );

              ondsList.push({
                originDestination: onds[i],
                agencyBookings: travelAgencyBookings,
                competitionBookings: competitionBookings,
                marketBookings: marketBookings,
                agencyShare: (travelAgencyBookings / marketBookings) * 100,
                competitionShare: (competitionBookings / marketBookings) * 100,
                agencyIncrement:
                  (travelAgencyBookings / travelAgencyBookingsPrevPeriod) *
                    100 -
                  100,
                competitionIncrement:
                  (competitionBookings / competitionBookingsPrevPeriod) * 100 -
                  100,
                marketIncrement:
                  (marketBookings / marketBookingsPrevPeriod) * 100 - 100,
              });
            } else {
              console.error("Error: unexpected data structure");
            }
          }
        } else {
          for (var j = 0; j < onds.length; j++) {
            travelAgencyBookings = dataset.Data({
              origin_destination: onds[j],
              agency_type: "travel_agency",
            })[0].value;
            competitionBookings = dataset.Data({
              origin_destination: onds[j],
              agency_type: "competition",
            })[0].value;
            marketBookings = dataset.Data({
              origin_destination: onds[j],
              agency_type: "market",
            })[0].value;

            ondsList.push({
              originDestination: onds[j],
              agencyBookings: travelAgencyBookings,
              competitionBookings: competitionBookings,
              marketBookings: marketBookings,
              agencyShare: (travelAgencyBookings / marketBookings) * 100,
              competitionShare: (competitionBookings / marketBookings) * 100,
            });
          }
        }
      } catch (e) {
        console.error("getTopAirlines error " + e);
        throw e;
      }
      return ondsList;
    },
  };
}

export default parsingUtilsFactory;
