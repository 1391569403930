import tri_down from "../../assets/images/tri_down.png";
import Pagination from "react-js-pagination";
require("bootstrap/less/bootstrap.less");
import "../../amadeus.css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveCriteria,
  saveRowSelected,
  saveView,
  saveHighlightedAirline,
  saveHighlightedDest,
  saveHighlightedOrigin,
  saveHighlightedAirlineUpdate,
  saveHighlightedDestUpdate,
  saveHighlightedOriginUpdate,
} from "../../redux/App/app.actions";
import CommonService from "../../utilities/commonService";

function Table(props) {
  const [tableData, setTableData] = useState();
  useEffect(() => {
    pageSelected(1);
    setPaginate((prev) => ({
      ...prev,
      maxSize: props.props.dataList.length || 1,
    }));
  }, [props && props.props && props.props.dataList]);
  const [paginate, setPaginate] = useState({
    totalItems: 0,
    currentPage: 1,
    maxSize: props.props.dataList.length || 1,
    itemsPerPage: 10,
    start: 0,
    end: 18,
    activePage: 1,
  });

  const pageSelected = (ev) => {
    var end = ev * paginate.itemsPerPage;
    var start = end - paginate.itemsPerPage;

    var data = props && props.props.dataList;
    data = data.slice(start, end);
    setTableData({
      ...tableData,
      data: data,
    });
    setPaginate({
      ...paginate,
      activePage: ev,
    });
  };
  const formatVariation = (value, showSign) => {
    if (showSign) {
      return value && !isNaN(value) && isFinite(value)
        ? value.toFixed(0) + "%"
        : "--";
    } else {
      return value && !isNaN(value) && isFinite(value)
        ? Math.abs(value).toFixed(0) + "%"
        : "--";
    }
  };
  const formatMarketShare = (value) => {
    return value && !isNaN(value) && isFinite(value)
      ? Math.abs(value).toFixed(2) + "%"
      : "--";
  };
  const formatValue = (nStr) => {
    if (!nStr) {
      return "--";
    }
    return nStr;
  };
  const addCommas = (nStr) => {
    nStr = formatValue(nStr);
    if (nStr !== null && nStr !== "") {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1,$2");
      }
      return x1 + x2 == "--" ? "0" : x1 + x2;
    }
    return nStr;
  };

  const exportCompleteTableToCSV = () => {
    const data = props && props.props.dataList;
    var finalVal = "";
    var dato;
    if (data !== null) {
      finalVal =
        (props.view == "onds" ? "Origin and Destination" : "Airline") +
        ",TravelAgency,TravelAgency,TravelAgency,Competition,Competition,Competition,Market,Market\n";
      finalVal +=
        ",Booked Passengers,Increment/Decrement,Market Share,Booked Passengers,Increment/Decrement,Market Share,Booked Passengers,Increment/Decrement,\n";
      for (var i = 0; i < data.length; i++) {
        dato = data[i];
        finalVal +=
          formatValue(dato.airline || dato.originDestination) +
          "," +
          (formatValue(dato.agencyBookings) === "--"
            ? "0"
            : formatValue(dato.agencyBookings)) +
          "," +
          formatVariation(dato.agencyIncrement, true) +
          "," +
          formatMarketShare(dato.agencyShare) +
          "," +
          (formatValue(dato.competitionBookings) === "--"
            ? "0"
            : formatValue(dato.competitionBookings)) +
          "," +
          formatVariation(dato.competitionIncrement, true) +
          "," +
          formatMarketShare(dato.competitionShare) +
          "," +
          (formatValue(dato.marketBookings) === "--"
            ? "0"
            : formatValue(dato.marketBookings)) +
          "," +
          formatVariation(dato.marketIncrement, true) +
          "\n";
      }

      finalVal +=
        "\nTOTAL," +
        (formatValue(totals.agencyBookings) === "--"
          ? "0"
          : formatValue(totals.agencyBookings)) +
        "," +
        formatVariation(totals.agencyIncrement, true) +
        "," +
        formatMarketShare(totals.agencyShare) +
        "," +
        (formatValue(totals.competitionBookings) === "--"
          ? "0"
          : formatValue(totals.competitionBookings)) +
        "," +
        formatVariation(totals.competitionIncrement, true) +
        "," +
        formatMarketShare(totals.competitionShare) +
        "," +
        (formatValue(totals.marketBookings) === "--"
          ? "0"
          : formatValue(totals.marketBookings)) +
        "," +
        formatVariation(totals.marketIncrement, true) +
        "\n";
    }

    var pom = document.getElementById("exportToCSV");
    var blob = new Blob([finalVal], { type: "text/csv;charset=utf-8" });
    var csvUrl = URL.createObjectURL(blob);
    pom.setAttribute("href", csvUrl);
    pom.setAttribute(
      "download",
      (props.view == "onds" ? "Origin-And-Destinations" : "Airlines") + ".csv"
    );
  };
  const orderTableBy = (value) => {
    props.saveCriteria({ ...props.criteria, sortBy: value });
  };
  const getArrow = function (value) {
    var pseudoClass = "";
    if (value > 0) {
      pseudoClass = "upArrow";
    } else if (value < 0) {
      pseudoClass = "downArrow";
    } else {
      pseudoClass = "noArrow";
    }
    return pseudoClass;
  };
  const changeSelectedRow = (selectedRowId) => {
    if (selectedRowId !== props.rowSelected) {
      props.saveRowSelected(selectedRowId);
      saveSelectedRowForUpdate(selectedRowId);
    } else {
      props.saveRowSelected(undefined);
    }
    if (props.view !== "onds") {
      props.saveCriteria({
        ...props.criteria,
        highlightedMarketingCarrier:
          selectedRowId !== props.rowSelected ? selectedRowId : undefined,
      });
    } else {
      props.saveCriteria({
        ...props.criteria,
        highlightedOnd:
          selectedRowId !== props.rowSelected ? selectedRowId : undefined,
      });
    }
  };

  const saveSelectedRowForUpdate = (row) => {
    props.saveHighlightedAirlineUpdate("");
    props.saveHighlightedDestUpdate("");
    props.saveHighlightedOriginUpdate("");
    if (row.length <= 3) {
      retrieveAirlines(row).then((matches) => {
        const results = matches.map((item) => [
          (item.iata_code === undefined ? "" : item.iata_code) +
            " (" +
            item.name +
            ")",
          item,
        ]);
        if (results.length === 0) {
          props.saveHighlightedAirlineUpdate(row);
        } else {
          props.saveHighlightedAirlineUpdate(results.slice(0, 1));
        }
      });
    } else {
      const od = row.split("-");
      retrieveLocations(od[0]).then((matches) => {
        const results = matches.map((item) => [
          item.id + " (" + item.name + ")",
          item,
        ]);
        if (results.length === 0) {
          props.saveHighlightedOriginUpdate(od[0]);
        } else {
          props.saveHighlightedOriginUpdate(results.slice(0, 1));
        }
      });
      retrieveLocations(od[1]).then((matches) => {
        const results = matches.map((item) => [
          item.id + " (" + item.name + ")",
          item,
        ]);
        if (results.length === 0) {
          props.saveHighlightedDestUpdate(od[1]);
        } else {
          props.saveHighlightedDestUpdate(results.slice(0, 1));
        }
      });
    }
  };
  const retrieveLocations = (input) => {
    if (input.length < 2) {
      return [];
    }
    return CommonService().getAirportsTypeAhead(input);
  };
  const retrieveAirlines = (input) => {
    if (input.length < 2) {
      return [];
    }
    return CommonService().getMarketingCarriersTypeAhead(input);
  };
  const airlinesOf = (event, ond) => {
    event.stopPropagation();
    const od = ond.split("-");
    retrieveLocations(od[0]).then((matches) => {
      const results = matches.map((item) => [
        item.id + " (" + item.name + ")",
        item,
      ]);
      if (results.length === 0) {
        props.saveHighlightedOrigin(od[0]);
      } else {
        props.saveHighlightedOrigin(results.slice(0, 1));
      }
    });
    retrieveLocations(od[1]).then((matches) => {
      const results = matches.map((item) => [
        item.id + " (" + item.name + ")",
        item,
      ]);
      if (results.length === 0) {
        props.saveHighlightedDest(od[1]);
      } else {
        props.saveHighlightedDest(results.slice(0, 1));
      }
    });
    props.saveCriteria({
      ...props.criteria,
      highlightedOnd: ond,
    });
    props.saveView("airlines");
    props.saveRowSelected("");
  };
  const ondsOf = (event, airline) => {
    retrieveAirlines(airline).then((matches) => {
      const results = matches.map((item) => [
        (item.iata_code === undefined ? "" : item.iata_code) +
          " (" +
          item.name +
          ")",
        item,
      ]);
      if (results.length === 0) {
        props.saveHighlightedAirline(airline);
      } else {
        props.saveHighlightedAirline(results.slice(0, 1));
      }
    });
    event.stopPropagation();
    props.saveCriteria({
      ...props.criteria,
      highlightedMarketingCarrier: airline,
    });
    props.saveView("onds");

    props.saveRowSelected("");
  };
  const data = tableData && tableData.data;
  const totals = props && props.props.dataListTotal;
  return (
    <div className="col-sm-12 col-md-6 col-lg-6">
      <table className="tableAmadeus">
        <thead>
          <tr className="handCursorOnTh">
            <th></th>
            <th
              colSpan="2"
              className="centerMiddle"
              onClick={() => orderTableBy("travel_agency")}
            >
              <span className="blueFont">
                ___ Travel Agency
                <img
                  alt="asc"
                  className="alignLeft"
                  height="8"
                  hidden={props.criteria.sortBy !== "travel_agency"}
                  src={tri_down}
                  width="10"
                />
                ___
              </span>
            </th>
            <th
              className="centerMiddle"
              colSpan="2"
              onClick={() => orderTableBy("competition")}
            >
              <span className="blueFont">
                ___ Competition
                <img
                  alt="asc"
                  className="alignLeft"
                  height="8"
                  hidden={props.criteria.sortBy !== "competition"}
                  src={tri_down}
                  width="10"
                />
                ___
              </span>
            </th>
            <th
              colSpan="2"
              className="centerMiddle"
              onClick={() => orderTableBy("market")}
            >
              <span className="blueFont">
                __ Market
                <img
                  alt="asc"
                  className="alignLeft"
                  height="8"
                  hidden={props.criteria.sortBy !== "market"}
                  src={tri_down}
                  width="10"
                />
                __
              </span>
            </th>
          </tr>
          <tr>
            <th className="centerMiddle cellTotals {{(orderProperty == 'airline') ? 'selectedColumn':''}}">
              O&amp;D
            </th>
            <th className="centerMiddle {{(orderProperty == 'agencyBookings') ? 'selectedColumn':''}}">
              Booked Passengers
            </th>
            <th className="centerMiddle {{(orderProperty == 'agencyShare') ? 'selectedColumn':''}}">
              Market Share
            </th>
            <th className="centerMiddle {{(orderProperty == 'competitionBookings') ? 'selectedColumn':''}}">
              Booked Passengers
            </th>
            <th className="centerMiddle {{(orderProperty == 'competitionShare') ? 'selectedColumn':''}}">
              Market Share
            </th>
            <th className="centerMiddle {{(orderProperty == 'marketBookings') ? 'selectedColumn':''}}">
              Booked Passengers
            </th>
          </tr>
        </thead>

        {data &&
          data.map((dato, index) => {
            return (
              <tbody
                className={`tableBody ${
                  props.rowSelected ===
                  (props.view == "onds" ? dato.originDestination : dato.airline)
                    ? "originsAndDestinationsButton"
                    : "cellNotSelected"
                } ${
                  props.rowSelected ===
                  (props.view == "onds" ? dato.originDestination : dato.airline)
                    ? "selectedRow"
                    : ""
                }`}
                key={index}
                id={
                  props.view == "onds" ? dato.originDestination : dato.airline
                }
                name="{{dato.airline}}"
                onClick={() =>
                  changeSelectedRow(
                    props.view == "onds" ? dato.originDestination : dato.airline
                  )
                }
              >
                <tr className="upperBorderAirlineRepeater">
                  <td className="backgroundTdRepeater">
                    {dato.originDestination || dato.airline}
                  </td>
                  <td className="blackFont">
                    {addCommas(dato.agencyBookings)}
                  </td>
                  <td>{formatMarketShare(dato.agencyShare)}</td>
                  <td className="blackFont">
                    {addCommas(dato.competitionBookings)}
                  </td>
                  <td>{formatMarketShare(dato.competitionShare)}</td>
                  <td className="blackFont">
                    {addCommas(dato.marketBookings)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className={
                        props.view == "onds" ? "buttonAirlines" : "buttonOandD"
                      }
                      onClick={(e) => {
                        props.view == "onds"
                          ? airlinesOf(e, dato.originDestination)
                          : ondsOf(e, dato.airline);
                      }}
                      hidden={
                        props.rowSelected !==
                        (props.view == "onds"
                          ? dato.originDestination
                          : dato.airline)
                      }
                    />
                  </td>
                  <td>
                    <span className={getArrow(dato.agencyIncrement)}>
                      {formatVariation(dato.agencyIncrement)}
                    </span>
                  </td>
                  <td></td>
                  <td>
                    <span className={getArrow(dato.competitionIncrement)}>
                      {formatVariation(dato.competitionIncrement)}
                    </span>
                  </td>
                  <td></td>
                  <td>
                    <span className={getArrow(dato.marketIncrement)}>
                      {formatVariation(dato.marketIncrement)}
                    </span>
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>

      <br />
      <table className="tableAmadeus">
        <tr>
          <td className="backgroundTdRepeater cellTotals">TOTAL</td>
          <td className="blackFont totalsAgencyBookingsCell">
            {addCommas(totals && totals.agencyBookings)}
          </td>
          <td className="totalsMarketShare">
            {formatMarketShare(totals && totals.agencyShare)}
          </td>
          <td className="blackFont compBookedPassengers">
            {addCommas(totals && totals.competitionBookings)}
          </td>
          <td className="compMarketShare">
            {formatMarketShare(totals && totals.competitionShare)}
          </td>
          <td className="blackFont marketBookedPassengers">
            {addCommas(totals && totals.marketBookings)}
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <span className={getArrow(totals.agencyIncrement)}>
              {formatVariation(totals && totals.agencyIncrement)}
            </span>
          </td>
          <td></td>
          <td>
            <span className={getArrow(totals.competitionIncrement)}>
              {formatVariation(totals && totals.competitionIncrement)}
            </span>
          </td>
          <td></td>
          <td>
            <span className={getArrow(totals.marketIncrement)}>
              {formatVariation(totals && totals.marketIncrement)}
            </span>
          </td>
        </tr>
      </table>

      <div className="excelIcon">
        <a
          className="handCursor"
          id="exportToCSV"
          onClick={exportCompleteTableToCSV}
        >
          Export to CSV
        </a>
      </div>
      <div className="container">
        <Pagination
          activePage={paginate.activePage}
          itemsCountPerPage={paginate.itemsPerPage}
          totalItemsCount={paginate.maxSize}
          pageRangeDisplayed={Math.ceil(paginate.maxSize / 10)}
          onChange={(ev) => pageSelected(ev)}
          firstPageText={"First"}
          prevPageText={"Previous"}
          nextPageText={"Next"}
          lastPageText={"Last"}
        />
        <div>
          Page: {paginate.activePage} / {Math.ceil(paginate.maxSize / 10)}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    criteria: state.app && state.app.criteria,
    view: state.app && state.app.view,
    rowSelected: state.app && state.app.rowSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveView: (view) => dispatch(saveView(view)),
    saveCriteria: (criteria) => dispatch(saveCriteria(criteria)),
    saveRowSelected: (rowSelected) => dispatch(saveRowSelected(rowSelected)),
    saveHighlightedOrigin: (from) => dispatch(saveHighlightedOrigin(from)),
    saveHighlightedDest: (to) => dispatch(saveHighlightedDest(to)),
    saveHighlightedAirline: (airline) =>
      dispatch(saveHighlightedAirline(airline)),

    saveHighlightedOriginUpdate: (from) =>
      dispatch(saveHighlightedOriginUpdate(from)),
    saveHighlightedDestUpdate: (to) => dispatch(saveHighlightedDestUpdate(to)),
    saveHighlightedAirlineUpdate: (airline) =>
      dispatch(saveHighlightedAirlineUpdate(airline)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Table);
