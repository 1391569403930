import { combineReducers } from 'redux';


import app from './App/app.reducer';


const rootReducer = combineReducers({

    app:app
    

});

export default rootReducer;