import { cloneDeep } from "lodash";
import CommonService from "./commonService";

function bookingCriteriaFactory() {
  return {
    /****** AUXILIARY FUNCTIONS **************/
    emptyStringToUndefined: function (value) {
      return value && value.trim() === "" ? undefined : value;
    },
    undefinedToEmptyString: function (value) {
      return !value ? "" : value;
    },
    isBlank: function (value) {
      return !value || value === null || value.trim() === "";
    },
    toTrimedString: function (value) {
      return this.undefinedToEmptyString(value).trim();
    },
    getSelectedDate: function (selectedDate) {
      var out = "";
      var appendYear = true;
      if (!this.isBlank(selectedDate.quarter)) {
        out += "quarter=" + selectedDate.quarter;
      } else if (!this.isBlank(selectedDate.month)) {
        out += "month=" + selectedDate.month;
      } else if (!this.isBlank(selectedDate.week)) {
        out += "week=" + selectedDate.week;
      } else if (selectedDate.specificDate && selectedDate.specificDate) {
        out +=
          "specificDate=" +
          selectedDate.specificDate.getDate() +
          "-" +
          (selectedDate.specificDate.getMonth() + 1) +
          "-" +
          selectedDate.specificDate.getFullYear();
        appendYear = false;
      }
      if (appendYear) {
        if (out !== "") {
          out += "-";
        } else {
          out += "year=";
        }
        out += selectedDate.year;
      }

      return out;
    },
    parseSelectedDate: function (selectedDate) {
      var newSelectedDate = {};
      if (selectedDate) {
        var field = selectedDate.split("=");
        var dateSplit = field[1].split("-");
        if (field[0] === "year") {
          newSelectedDate.year = field[1];
        } else if (field[0] === "quarter") {
          newSelectedDate.quarter = dateSplit[0];
          newSelectedDate.year = dateSplit[1];
        } else if (field[0] === "month") {
          newSelectedDate.month = dateSplit[0];
          newSelectedDate.year = dateSplit[1];
        } else if (field[0] === "week") {
          newSelectedDate.week = dateSplit[0];
          newSelectedDate.year = dateSplit[1];
        } else if (field[0] === "specificDate") {
          newSelectedDate.specificDate = new Date(
            dateSplit[2],
            dateSplit[1] - 1,
            dateSplit[0]
          );
          newSelectedDate.year = dateSplit[2];
        }
      } else {
        newSelectedDate = undefined;
      }
      return newSelectedDate;
    },

    getFromOnD: function (what, ond) {
      var out;
      if (ond) {
        var ondArray = ond.split("-");
        out =
          ondArray && ondArray.length >= (what === "destination" ? 2 : 1)
            ? ondArray[what === "destination" ? 1 : 0]
            : undefined;
      }
      return out;
    },
    getOriginAirportIataCode: function (origin, highlightedOnd) {
      var orig = origin ? this.emptyStringToUndefined(origin.id) : undefined;
      if (!this.isBlank(highlightedOnd)) {
        orig = this.getFromOnD("origin", highlightedOnd);
      }
      return orig;
    },
    getDestinationAirportIataCode: function (destination, highlightedOnd) {
      var dest = destination
        ? this.emptyStringToUndefined(destination.id)
        : undefined;
      if (!this.isBlank(highlightedOnd)) {
        dest = this.getFromOnD("destination", highlightedOnd);
      }
      return dest;
    },
    getMarketingCarrier: function (
      marketingCarrier,
      highlightedMarketingCarrier
    ) {
      var mc = this.emptyStringToUndefined(
        marketingCarrier ? marketingCarrier.iata_code : ""
      );
      if (mc && typeof mc === "string") {
        mc = mc.trim();
      }
      var hmc = this.emptyStringToUndefined(highlightedMarketingCarrier);
      if (!mc && hmc) {
        mc = hmc;
      }
      return mc;
    },
    findAirport: function (iataCode) {
      if (!iataCode) {
        return undefined;
      }
      var i = 0;
      var encontrado = false;

      return ComCommonService()()
        .getAirportsTypeAhead("code:" + iataCode)
        .then(function (ports) {
          while (!encontrado && i < ports.length) {
            encontrado = ports[i].id === iataCode;
            if (!encontrado) i++;
          }
          return encontrado ? ports[i] : iataCode;
        });
    },
    findCarrier: function (iataCode) {
      if (iataCode) {
        return ComCommonService()()
          .getMarketingCarriersTypeAhead("code:" + iataCode.trim())
          .then(function (carriers) {
            for (var i = 0; i < carriers.length; i++) {
              if (
                carriers[i].iata_code &&
                carriers[i].iata_code.trim() === iataCode.trim()
              ) {
                return carriers[i];
              }
            }
            return iataCode;
          });
      }
      return undefined;
    },
    findById: function (id, collection) {
      var i = 0;
      var encontrado = false;
      if (id) {
        while (!encontrado && i < collection.length) {
          encontrado = collection[i].id.trim() === id.trim();
          if (!encontrado) i++;
        }
      }
      return encontrado ? collection[i] : id;
    },
    /************* END AUXILIARY FUNCTIONS *********************/
    getWeek: function (date) {
      var target = date;

      // ISO week date weeks start on monday
      // so correct the day number
      var dayNr = (date.getDay() + 6) % 7;

      // ISO 8601 states that week 1 is the week
      // with the first thursday of that year.
      // Set the target date to the thursday in the target week
      target.setDate(target.getDate() - dayNr + 3);

      // Store the millisecond value of the target date
      var firstThursday = target.valueOf();

      // Set the target to the first thursday of the year
      // First set the target to january first
      target.setMonth(0, 1);
      // Not a thursday? Correct the date to the next thursday
      if (target.getDay() !== 4) {
        target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
      }

      // The weeknumber is the number of weeks between the
      // first thursday of the year and the thursday in the target week
      return 1 + Math.ceil((firstThursday - target) / 604800000); // 604800000 = 7 * 24 * 3600 * 1000
    },
    singleDateToParam: function (date) {
      var param = "";

      if (date.quarter) {
        param = date.year + "-" + date.quarter;
      } else if (date.month) {
        param = date.year + "-" + date.month;
      } else if (date.week) {
        param = date.year + "-" + date.week;
      } else if (date.specificDate) {
        param =
          date.specificDate.getFullYear() +
          "-" +
          (date.specificDate.getMonth() + 1) +
          "-" +
          date.specificDate.getDate();
      } else {
        param = date.year;
      }
      return param;
    },
    getPreviousQuarter: function (previousDate) {
      var quarter = previousDate.quarter.substring(1, 2);
      if (Number(quarter) === 1) {
        previousDate.quarter = "Q4";
        previousDate.year = previousDate.year - 1;
      } else {
        previousDate.quarter = "Q" + (quarter - 1);
      }
      return previousDate;
    },
    getPreviousMonth: function (previousDate) {
      if (Number(previousDate.month) === 1) {
        previousDate.month = 12;
        previousDate.year = previousDate.year - 1;
      } else {
        previousDate.month = previousDate.month - 1;
      }
      return previousDate;
    },
    getPreviousWeek: function (previousDate) {
      var week = previousDate.week.substring(1, previousDate.week.length);
      if (Number(week) === 1) {
        var lastThursdayPreviousYear = new Date(previousDate.year - 1, 11, 31);
        var count = 1;
        //The week starts on thursday since we need the lastThursday
        while (lastThursdayPreviousYear.getDay() !== 4) {
          lastThursdayPreviousYear = new Date(
            previousDate.year - 1,
            11,
            31 - count
          );
          ++count;
        }

        previousDate.week = "W" + this.getWeek(lastThursdayPreviousYear);
        previousDate.year = previousDate.year - 1;
      } else {
        previousDate.week = "W" + (week - 1);
      }
      return previousDate;
    },
    getPreviousDay: function (specificDate) {
      var day = specificDate.getDate() - 1;
      specificDate = new Date(
        specificDate.getFullYear(),
        specificDate.getMonth(),
        day
      );
      return specificDate;
    },
    getPreviousYear: function (specificDate) {
      var year = specificDate.getFullYear() - 1;
      specificDate = new Date(
        year,
        specificDate.getMonth(),
        specificDate.getDate()
      );
      return specificDate;
    },
    /* Converts the selectedDate in the criteria object into an http param in the format required by the services */
    selectedDateToParam: function (
      selectedDate,
      periodComparisonType,
      dateComparision
    ) {
      var previousDate = cloneDeep(selectedDate);
      if (periodComparisonType === "samePeriodPreviousYear") {
        previousDate.year = String(Number(previousDate.year) - 1);
        if (previousDate.specificDate) {
          previousDate.specificDate = this.getPreviousYear(
            previousDate.specificDate
          );
        }
      } else if (periodComparisonType === "previousPeriod") {
        if (selectedDate.quarter) {
          previousDate = this.getPreviousQuarter(previousDate);
        } else if (selectedDate.month) {
          previousDate = this.getPreviousMonth(previousDate);
        } else if (selectedDate.week) {
          previousDate = this.getPreviousWeek(previousDate);
        } else if (selectedDate.specificDate) {
          dateComparision = false;
          previousDate.specificDate = this.getPreviousDay(
            selectedDate.specificDate
          );
        } else {
          //previousYear
          previousDate.year = String(Number(previousDate.year) - 1);
        }
      }
      if (dateComparision) {
        return (
          this.singleDateToParam(previousDate) +
          "," +
          this.singleDateToParam(selectedDate)
        );
      } else {
        return this.singleDateToParam(selectedDate);
      }
    },
    getDateOfISOWeek: function (w, y) {
      var simple = new Date(y, 0, 1 + (w - 1) * 7);
      var dow = simple.getDay();
      var ISOweekStart = simple;
      if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      }
      return ISOweekStart;
    },
    getPeriodToDate: function (criteria) {
      var limit_in_days;
      var today = new Date();
      var criteriaYear = parseInt(criteria.selectedDate.year);

      if (criteriaYear === today.getFullYear()) {
        if (criteria.selectedDate.quarter) {
          var quarter = parseInt(criteria.selectedDate.quarter.substring(1, 2));
          var currentQuarter = (today.getMonth() + 1) / 4;
          currentQuarter = Math.ceil(currentQuarter);
          if (quarter === currentQuarter) {
            var initialMonth = quarter * 3 - 3;
            var quarterInitDate = new Date(
              today.getFullYear(),
              initialMonth,
              1
            );
            limit_in_days = (today - quarterInitDate) / (1000 * 60 * 60 * 24);
          } else {
            limit_in_days = -1;
          }
        } else if (criteria.selectedDate.month) {
          var criteriaMonth = parseInt(criteria.selectedDate.month);
          var initMonthDate = new Date(
            today.getFullYear(),
            criteriaMonth - 1,
            1
          );
          if (criteriaMonth - 1 === today.getMonth()) {
            limit_in_days = (today - initMonthDate) / (1000 * 60 * 60 * 24);
          } else {
            limit_in_days = -1;
          }
        } else if (criteria.selectedDate.week) {
          var week = parseInt(
            criteria.selectedDate.week.substring(
              1,
              criteria.selectedDate.week.length
            )
          );
          var currentWeek = this.getWeek(new Date());
          if (week === currentWeek) {
            var initDateOfWeek = this.getDateOfISOWeek(
              week,
              today.getFullYear()
            );
            var firstDayNextWeek = new Date(
              initDateOfWeek.getFullYear(),
              initDateOfWeek.getMonth(),
              initDateOfWeek.getDate() + 7
            );
            if (today < firstDayNextWeek) {
              limit_in_days = (today - initDateOfWeek) / (1000 * 60 * 60 * 24);
            } else {
              limit_in_days = -1;
            }
          } else {
            limit_in_days = -1;
          }
        } else if (criteria.selectedDate.specificDate) {
          //We do not take into considatation the specific date here
          return undefined;
        } else {
          if (criteriaYear === today.getFullYear()) {
            var yearInit = new Date(criteriaYear, 0, 1);
            limit_in_days = (today - yearInit) / (1000 * 60 * 60 * 24);
          } else {
            limit_in_days = -1;
          }
        }
      } else {
        limit_in_days = -1;
      }
      return Math.ceil(limit_in_days);
    },
    setOriginAndDestinationToParams: function (params, criteria) {
      var originProperty =
        "origin_" +
        (criteria.selectedOrigin.type === "airport" ||
        !criteria.selectedOrigin.type ||
        !this.isBlank(criteria.highlightedOnd)
          ? "port"
          : criteria.selectedOrigin.type);
      var destinationProperty =
        "destination_" +
        (criteria.selectedDestination.type === "airport" ||
        !criteria.selectedDestination.type ||
        !this.isBlank(criteria.highlightedOnd)
          ? "port"
          : criteria.selectedDestination.type);
      params[originProperty] = this.getOriginAirportIataCode(
        criteria.selectedOrigin,
        criteria.highlightedOnd
      );
      params[destinationProperty] = this.getDestinationAirportIataCode(
        criteria.selectedDestination,
        criteria.highlightedOnd
      );
      return params;
    },
    createParamsObject: function (criteria, dateComparision) {
      var pos = criteria.pointOfSale ? criteria.pointOfSale.id : "";
      var markt = criteria.market ? criteria.market.id : "";
      var params = {
        booking_period:
          criteria.dateType === "booking"
            ? this.selectedDateToParam(
                criteria.selectedDate,
                criteria.periodComparisonType,
                dateComparision
              )
            : undefined,
        departure_period:
          criteria.dateType === "departure"
            ? this.selectedDateToParam(
                criteria.selectedDate,
                criteria.periodComparisonType,
                dateComparision
              )
            : undefined,
        pos_oid: this.emptyStringToUndefined(pos),
        pos_country: this.emptyStringToUndefined(markt),
        marketing_carrier: this.getMarketingCarrier(
          criteria.marketingCarrier,
          criteria.highlightedMarketingCarrier
        ),
        cabin_class: this.emptyStringToUndefined(criteria.cabinClass),
        sort_by: criteria.sortBy,
      };

      params = this.setOriginAndDestinationToParams(params, criteria);

      if (criteria.period === "periodToDate") {
        var limitInDays = this.getPeriodToDate(criteria);
        if (limitInDays >= 0) {
          params.limit_in_days = limitInDays;
        }
      }

      return params;
    },
    /* Converts the criteria object into http params in the format required by the services */
    criteriaToHttpParams: function (criteria, dateComparation) {
      return this.createParamsObject(criteria, dateComparation);
    },

    criteriaToURL: function (criteria) {
      const pos = criteria.pointOfSale ? criteria.pointOfSale.id : "";
      const markt = criteria.market ? criteria.market.id : "";

      return [
        "",
        this.toTrimedString(
          this.getOriginAirportIataCode(
            criteria.selectedOrigin,
            criteria.highlightedOnd
          )
        ),
        this.toTrimedString(
          this.getDestinationAirportIataCode(
            criteria.selectedDestination,
            criteria.highlightedOnd
          )
        ),
        this.toTrimedString(
          this.getMarketingCarrier(
            criteria.marketingCarrier,
            criteria.highlightedMarketingCarrier
          )
        ),
        this.toTrimedString(criteria.dateType),
        this.getSelectedDate(criteria.selectedDate),
        this.toTrimedString(criteria.periodComparisonType),
        this.toTrimedString(criteria.period),
        this.toTrimedString(pos),
        this.toTrimedString(markt),
        this.toTrimedString(criteria.cabinClass),
      ].join("/");
    },

    fillCriteriaWithRouteParams: function (
      criteria,
      routeParams,
      pointsOfSale,
      markets
    ) {
      const selectedDate = this.parseSelectedDate(routeParams.selectedDate);

      criteria.selectedOrigin = this.undefinedToEmptyString(
        this.findAirport(routeParams.selectedOrigin)
      );
      criteria.selectedDestination = this.undefinedToEmptyString(
        this.findAirport(routeParams.selectedDestination)
      );
      criteria.marketingCarrier = this.undefinedToEmptyString(
        this.findCarrier(routeParams.selectedAirline)
      );
      criteria.pointOfSale = this.undefinedToEmptyString(
        this.findById(routeParams.pointOfSale, pointsOfSale)
      );
      criteria.market = this.undefinedToEmptyString(
        this.findById(routeParams.market, markets)
      );
      criteria.cabinClass = this.undefinedToEmptyString(routeParams.cabinClass);

      if (!this.isBlank(routeParams.dateType)) {
        criteria.dateType = routeParams.dateType;
      }
      if (selectedDate) {
        criteria.selectedDate = selectedDate;
      }
      if (!this.isBlank(routeParams.periodComparisionType)) {
        criteria.periodComparisonType = routeParams.periodComparisionType;
      }
      if (!this.isBlank(routeParams.period)) {
        criteria.period = routeParams.period;
      }
      return criteria;
    },
  };
}

export default bookingCriteriaFactory;
