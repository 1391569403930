import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { initialize, login } from "@vana/ami-ui-authentication";
import { SAVETOKEN, GETPREFERENCES, GETUSER } from "./redux/App/app.types";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import apiFetch from "./main";
export const env = initialize({ appUrl: "agency" });
// add
if (env.code === '__dev__') {
  env.code = 'qa';

  env.prefix = 'qa-';

  env.uiPortalUrl = 'https://qa-login.travel-intelligence.com';

  env.apiUrl = 'https://qa-wsp.travel-intelligence.com';
}

window.apiProxyUrl = env.apiUrl;
export const servicesUrl = env.apiUrl + "/api/v1";
export const dataServicesUrl = env.apiUrl + "/taba/api/v1";
export const uiServicesUrl = env.apiUrl + "/ui-services/api";

login().then((res) => {
  store.dispatch({ type: SAVETOKEN, payLoad: res });
  store.dispatch({ type: GETUSER, payLoad: res.user });
  apiFetch({
    url: '/user-data/preferences',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json', // Assuming the token is in the response
    },
  }).then((response) => {
    store.dispatch({ type: GETPREFERENCES, payLoad: response });
  });
});
const root = ReactDOM.createRoot(document.getElementById("root"));

if (root) {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
