import React, { useEffect, useState } from "react";
import "./dateselector.css";

function DateSelector(props) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [monthSelected, setSelectedMonth] = useState(
    props.provisional.selectedDate.month
  );
  const formatDate = (date) => {
    if (date === "") {
      return "";
    }
    return (
      date.getDate() +
      "-" +
      monthNames[date.getMonth()] +
      "-" +
      date.getFullYear()
    );
  };
  useEffect(() => {
    setFormatedDate(formatDate(props.provisional.selectedDate.specificDate));
  }, [props.provisional.selectedDate.specificDate]);
  // Initializing years and weeks
  const [formatedDate, setFormatedDate] = useState("");
  const years = [];
  var year = new Date().getFullYear() + 1;
  const weeks = [];
  for (let index = year; index >= 2017; index--) {
    years.push(index);
  }
  for (var i = 1; i <= 52; i++) {
    weeks.push({ id: "W" + (i < 10 ? "0" + i : i), name: "Week " + i });
  }

  const [prevMonth, setPrevMonth] = useState(
    props.provisional.selectedDate.month
  );
  const [prevQuarter, setPrevQuarter] = useState(
    props.provisional.selectedDate.quarter
  );
  useEffect(() => {
    //for resetting month on clicking reset
    if (prevMonth === "" && props.provisional.selectedDate.month !== "") {
      setPrevMonth(props.provisional.selectedDate.month);
      document.getElementById(
        props.provisional.selectedDate.month
      ).style.cssText = `
        background-color: #b95d9c!important;
    `;
    }
    //setting month selected month color bg
    if (prevMonth !== "") {
      document.getElementById(prevMonth).style.cssText = `
        background-color: #428bca!important;
  `;
      setPrevMonth(props.provisional.selectedDate.month);
      if (props.provisional.selectedDate.month !== "") {
        document.getElementById(
          props.provisional.selectedDate.month
        ).style.cssText = `
          background-color: #b95d9c!important;
      `;
      }
    }
  }, [props.provisional.selectedDate.month]);

  useEffect(() => {
    if (prevQuarter !== "") {
      document.getElementById(prevQuarter).style.cssText =
        "background-color: #428bca!important;";
      setPrevQuarter(props.provisional.selectedDate.quarter);
      if (props.provisional.selectedDate.quarter !== "") {
        document.getElementById(
          props.provisional.selectedDate.quarter
        ).style.cssText = `
          background-color: #b95d9c!important;
      `;
      }
    }
  }, [props.provisional.selectedDate.quarter]);

  const yearOptions = years.map((option, index) => {
    return (
      <option value={option} key={index}>
        {option}
      </option>
    );
  });

  const weekOptions = weeks.map((option, index) => {
    return (
      <option value={option.id} key={option.id}>
        {option.name}
      </option>
    );
  });
  const handleMonthChange = (mon) => {
    if (prevMonth === "") {
      setPrevMonth(mon);
    }

    clearDateExcept("selectedMonth");
    props.updateProvisional((prev) => ({
      ...prev,
      selectedDate: { ...prev.selectedDate, month: mon },
    }));
  };
  const handleQuarterChange = (qtr) => {
    if (prevQuarter === "") {
      setPrevQuarter(qtr);
    }
    clearDateExcept("selectedQuarter");
    props.updateProvisional((prev) => ({
      ...prev,
      selectedDate: { ...prev.selectedDate, quarter: qtr },
    }));
  };
  const handleYearChange = (e) => {
    clearDateExcept("selectedYear");

    props.updateProvisional((prev) => ({
      ...prev,
      selectedDate: { ...prev.selectedDate, year: e.target.value },
    }));
  };
  const handleWeekChange = (e) => {
    clearDateExcept("selectedWeek");
    props.updateProvisional((prev) => ({
      ...prev,
      selectedDate: { ...prev.selectedDate, week: e.target.value },
    }));
  };
  const handleDateChange = (e) => {
    clearDateExcept("selectedSpecificDate");
    setFormatedDate(formatDate(new Date(e.target.value)));

    props.updateProvisional((prev) => ({
      ...prev,
      selectedDate: {
        ...prev.selectedDate,
        specificDate: new Date(e.target.value),
        year: e.target.value.slice(0, 4),
      },
    }));
  };
  const clearDateExcept = (exceptField) => {
    if (exceptField !== "selectedMonth") {
      props.updateProvisional((prev) => ({
        ...prev,
        selectedDate: { ...prev.selectedDate, month: "" },
      }));
    }
    if (exceptField !== "selectedQuarter") {
      props.updateProvisional((prev) => ({
        ...prev,
        selectedDate: { ...prev.selectedDate, quarter: "" },
      }));
    }
    if (exceptField !== "selectedWeek") {
      props.updateProvisional((prev) => ({
        ...prev,
        selectedDate: { ...prev.selectedDate, week: "" },
      }));
    }

    if (exceptField !== "selectedSpecificDate") {
      setFormatedDate("");

      props.updateProvisional((prev) => ({
        ...prev,
        selectedDate: { ...prev.selectedDate, specificDate: "" },
      }));
    }
  };

  return (
    <>
      <div className="date-selector-query input-group-sm col-sm-12 col-md-12 col-lg-12">
        <div className="btn-group input-group-sm date-selector-button-group">
          <select
            className="input-sm form-control form-select"
            value={props.provisional.selectedDate.year}
            onChange={handleYearChange}
          >
            {yearOptions}
          </select>
        </div>
        <div className="btn-group date-selector-button-group">
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleQuarterChange("Q1");
            }}
            id="Q1"
          >
            Q1
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleQuarterChange("Q2");
            }}
            id="Q2"
          >
            Q2
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleQuarterChange("Q3");
            }}
            id="Q3"
          >
            Q3
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleQuarterChange("Q4");
            }}
            id="Q4"
          >
            Q4
          </button>
        </div>
        <div className="btn-group date-selector-button-group">
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("1");
            }}
            id="1"
          >
            Jan
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("2");
            }}
            id="2"
          >
            Feb
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("3");
            }}
            id="3"
          >
            Mar
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("4");
            }}
            id="4"
          >
            Apr
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("5");
            }}
            id="5"
          >
            May
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("6");
            }}
            id="6"
          >
            Jun
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("7");
            }}
            id="7"
          >
            Jul
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("8");
            }}
            id="8"
          >
            Aug
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("9");
            }}
            id="9"
          >
            Sep
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("10");
            }}
            id="10"
          >
            Oct
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("11");
            }}
            id="11"
          >
            Nov
          </button>
          <button
            className="btn btn-primary btn-sm btn-date-selector"
            type="button"
            onClick={() => {
              handleMonthChange("12");
            }}
            id="12"
          >
            Dec
          </button>
        </div>
        <div className="btn-group input-group-sm date-selector-button-group">
          <select
            className="input-sm form-control"
            onChange={handleWeekChange}
            value={props.provisional.selectedDate.week}
          >
            <option defaultValue disabled value="">
              -Weeks-
            </option>
            {weekOptions}
          </select>
        </div>
        {props.dateType == "departure" && (
          <div className="date-selector-calendar" ng-show="showSpecificDate">
            <input
              className="inline-form-control data-selector-text dateSelectorWidth"
              type="text"
              placeholder="No date selected"
              disabled
              value={formatedDate}
            />
            <input
              type="date"
              className="dateSelectorImg"
              onChange={handleDateChange}
              id="increasesize"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default DateSelector;
