import React, { useEffect, useState } from "react";
import CommonService from "../../utilities/commonService";
import { saveCriteria } from "../../redux/App/app.actions";
import "../../amadeus.css";

import "./filterinput.css";
import { connect } from "react-redux";

function FilterInput(props) {
  const [posData, setPosData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const styles =
    props.objName === "pointOfSale" || props.objName === "market"
      ? { marginTop: "2px" }
      : { marginTop: "34px" };
  const marketingCarrierWidth =
    props.objName === "marketingCarrier" ? { width: "122px" } : {};

  const retrieveLocations = (input) => {
    if (input.length < 2) {
      return [];
    }
    return CommonService().getAirportsTypeAhead(input);
  };
  const retrieveAirlines = (input) => {
    if (input.length < 2) {
      return [];
    }
    return CommonService().getMarketingCarriersTypeAhead(input);
  };
  const retrieveMarket = () => {
    return CommonService()
      .getMarkets()
      .then((matches) => {
        const res = matches.map((item) => [
          item.id + " (" + item.name + ")",
          item,
        ]);
        res.sort();
        setMarketData(res);
      });
  };
  const retrievePOS = () => {
    return CommonService()
      .getPointsOfSale()
      .then((matches) => {
        const res = matches.map((item) => [
          item.id + " (" + item.name + ")",
          item,
        ]);
        res.sort();
        setPosData(res);
      });
  };
  useEffect(() => {
    retrievePOS();
    retrieveMarket();
  }, []);

  const [selected, setSelected] = useState([]);
  const [countryMatch, setCountryMatch] = useState([]);

  const [countrySelected, setCountrySelected] = useState(
    (props.objName === "pointOfSale" || props.objName === "market") &&
      props.property !== ""
      ? props.property.id + "(" + props.property.name + ")"
      : ""
  );
  const searchCountries = (text) => {
    if (countrySelected !== "") {
      setCountrySelected("");
    }
    setCountryMatch([]);
    props.updateData((prev) => ({
      ...prev,
      [props.objName]: text,
    }));

    switch (props.objName) {
      case "selectedDestination":
      case "selectedOrigin":
        if (text.length > 1) {
          retrieveLocations(text).then((matches) => {
            const results = matches.map((item) => [
              item.id + " (" + item.name + ")",
              item,
            ]);
            setCountryMatch(results.slice(0, 8));
          });
        }

        break;
      case "marketingCarrier":
        if (text.length > 1) {
          retrieveAirlines(text).then((matches) => {
            const results = matches.map((item) => [
              (item.iata_code === undefined ? "" : item.iata_code) +
                " (" +
                item.name +
                ")",
              item,
            ]);
            setCountryMatch(results.slice(0, 8));
          });
        }
        break;
      case "pointOfSale":
        var results = posData.reduce(function (filtered, data) {
          const regex = new RegExp(`${text}`, "gi");
          if (data[1].id.match(regex)) {
            filtered.push(data);
          }
          return filtered;
        }, []);

        setCountryMatch(results.slice(0, 8));

        break;
      case "market":
        var results = marketData.reduce(function (filtered, data) {
          const regex = new RegExp(`${text}`, "gi");
          if (data[0].match(regex)) {
            filtered.push(data);
          }
          return filtered;
        }, []);

        setCountryMatch(results.slice(0, 8));
        break;
      default:
        break;
    }
  };

  const clearTextAndSetCountry = (item) => {
    props.updateData((prev) => ({
      ...prev,
      [props.objName]: item[1],
    }));
    setCountrySelected(item[0]);
  };
  const focusLost = () => {
    setSelected(false);
    setCountryMatch([]);
    if (countrySelected === "") {
      props.updateData((prev) => ({
        ...prev,
        [props.objName]: "",
      }));
    }
  };
  useEffect(() => {
    if (props.property === "") {
      setCountrySelected("");
    }
  }, [props.property === ""]);

  useEffect(() => {
    if (props.objName === "marketingCarrier") {
      if (props.highlightedAirline !== "") {
        if (typeof props.highlightedAirline !== "string") {
          props.saveCriteria({
            ...props.criterias,
            marketingCarrier: props.highlightedAirline[0][1],
          });
          props.updateData((prev) => ({
            ...prev,
            marketingCarrier: props.highlightedAirline[0][1],
          }));
          props.updateCriteria((prev) => ({
            ...prev,
            marketingCarrier: props.highlightedAirline[0][1],
          }));
          setCountrySelected(props.highlightedAirline[0][0]);
        } else {
          setCountrySelected(props.highlightedAirline);
        }
      } else {
        props.saveCriteria({
          ...props.criterias,
          marketingCarrier: "",
        });
        props.updateData((prev) => ({
          ...prev,
          marketingCarrier: "",
        }));
        props.updateCriteria((prev) => ({
          ...prev,
          marketingCarrier: "",
        }));
        setCountrySelected("");
      }
    }
  }, [props.highlightedAirline]);
  useEffect(() => {
    if (props.objName === "selectedOrigin") {
      if (props.highlightedOrigin !== "") {
        if (typeof props.highlightedOrigin !== "string") {
          props.saveCriteria({
            ...props.criterias,
            selectedOrigin: props.highlightedOrigin[0][1],
          });
          props.updateData((prev) => ({
            ...prev,
            selectedOrigin: props.highlightedOrigin[0][1],
          }));
          props.updateCriteria((prev) => ({
            ...prev,
            selectedOrigin: props.highlightedOrigin[0][1],
          }));
          setCountrySelected(props.highlightedOrigin[0][0]);
        } else {
          setCountrySelected(props.highlightedOrigin);
        }
      } else {
        setCountrySelected("");
      }
    }
  }, [props.highlightedOrigin]);
  useEffect(() => {
    if (props.objName === "selectedDestination") {
      if (props.highlightedDest !== "") {
        if (typeof props.highlightedDest !== "string") {
          props.saveCriteria({
            ...props.criterias,
            selectedDestination: props.highlightedDest[0][1],
          });
          props.updateData((prev) => ({
            ...prev,
            selectedDestination: props.highlightedDest[0][1],
          }));
          props.updateCriteria((prev) => ({
            ...prev,
            selectedDestination: props.highlightedDest[0][1],
          }));
          setCountrySelected(props.highlightedDest[0][0]);
        } else {
          setCountrySelected(props.highlightedDest);
        }
      } else {
        setCountrySelected("");
      }
    }
  }, [props.highlightedDest]);

  return (
    <>
      <div onBlur={focusLost}>
        <input
          className="form-control input-size-set"
          placeholder={props.placeholder}
          style={marketingCarrierWidth}
          onChange={(e) => searchCountries(e.target.value)}
          onFocus={() => setSelected(true)}
          type="text"
          value={countrySelected === "" ? props.property : countrySelected}
        />
        {selected && countryMatch && (
          <div className="dropdown-content" style={styles}>
            {countryMatch.map((item, index) => (
              <a
                key={index}
                href="#"
                onMouseDown={() => {
                  clearTextAndSetCountry(item);
                }}
              >
                {item[0]}
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    criterias: state.app && state.app.criteria,
    highlightedDest: state.app && state.app.highlightedDest,
    highlightedOrigin: state.app && state.app.highlightedOrigin,
    highlightedAirline: state.app && state.app.highlightedAirline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCriteria: (criteria) => dispatch(saveCriteria(criteria)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterInput);
